
import { Parser } from 'json2csv';
import { downloadBlob } from './download-blob';

export const csvExporter = (filename: string, fields: Array<any>, data: Array<any>, header?: string) => {
  const opts = {
    fields,
  };

  let csv;
  try {
    const parser = new Parser(opts);
    csv = parser.parse(data);
  } catch (err) {
    return null;
  }

  if (!csv) return null;

  const blobData = [];
  if (header) {
    blobData.push(header);
  }
  blobData.push(csv);

  const blob = new Blob(blobData, { type: 'text/csv' });

  downloadBlob({ blob, filename });

  return true;
};
