import { useRouteError } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

export const ErrorPage = () => {
  const error: any = useRouteError();
  console.error(error);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="u-margin-top-xl"
      spacing={2}
    >
      <Grid item xs={8}>
        <Item>
          <span className="typo-h3 u-padding">Well, this is embarrassing!</span>
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
          <span className="u-padding">We hit an unexpected error.</span>
        </Item>
      </Grid>
    </Grid>
  );
};
