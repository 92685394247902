import React from 'react';
import classNames from 'classnames';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

type InputProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  title?: string;
  onClose?: () => void;
  children: React.ReactNode;
  open?: boolean;
};

export class DialogWrapper extends React.PureComponent<InputProps> {
  static Content = ({ children }: { children: React.ReactNode }) => (
    <div className="u-padding-lg">{children}</div>
  );

  static Actions = ({ children }: { children: React.ReactNode }) => {
    const childrenCount = React.Children.count(children);
    const style = classNames(
      'u-display-flex u-alignItems-center u-padding-lg u-no-padding-top',
      childrenCount > 1
        ? 'u-justifyContent-spaceBetween'
        : 'u-justifyContent-flexEnd',
    );

    return <div className={style}>{children}</div>;
  };

  render() {
    const { size, title, children, onClose, open } = this.props;
    const fullWidth = Boolean(size);
    const centeringClass = onClose
      ? 'u-justifyContent-spaceBetween'
      : 'u-justifyContent-center';

    const isOpen = typeof open === 'undefined' ? true : open;

    return (
      <Dialog
        fullWidth={fullWidth}
        open={isOpen}
        onClose={onClose}
        maxWidth={size}
        disableEnforceFocus
      >
        <div
          className={`u-display-flex u-alignItems-center u-background-primary typo-h6 u-color-white u-padding ${centeringClass}`}
        >
          {onClose && <div style={{ width: '24px' }} />}
          <div>{title}</div>
          {onClose && (
            <CloseIcon className="u-cursor-pointer" onClick={onClose} />
          )}
        </div>
        <div className="u-flexColumn u-overflow-auto">{children}</div>
      </Dialog>
    );
  }
}
