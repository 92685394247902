import Button from '@mui/material/Button';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Application,
  currentApplicationAtom,
  loadCurrentApplication,
} from '../../application/application.atoms';
import { NavigationController } from '../../bootstrap/history-spy';
import { DataWrapper } from '../../utilities/data-wrapper';
import { useAtomApi } from '../../utilities/use-fetch';
import {
  AdminPersonApplication,
  personApplicationInterviews,
  loadPersonApplicationInterviews,
} from '../view-applications/view-applications.atoms';
import {
  loadReaderCriterionForApplication,
  loadReaderScores,
  ReaderCriteria,
  readerCriterionForApplicationAtom,
  ReaderScore,
  readerScoresAtom,
} from './view-scores-atoms';

const average = (array: number[]) => {
  const val = array.reduce((a, b) => a + b, 0) / array.length;
  if (isNaN(val)) {
    return undefined;
  }
  return val;
};

export const ViewInterviewScoresPage = () => {
  const [
    currentApplicationLoading,
    currentApplicationError,
    currentApplication,
  ] = useAtomApi<Application>(loadCurrentApplication, currentApplicationAtom);
  return (
    <DataWrapper
      loading={currentApplicationLoading}
      error={currentApplicationError}
    >
      {currentApplication ? (
        <ViewScoresList
          currentApplicationId={currentApplication.applicationId}
        />
      ) : null}
    </DataWrapper>
  );
};

type ViewScoresListProps = {
  currentApplicationId: string;
};
export const ViewScoresList = ({
  currentApplicationId,
}: ViewScoresListProps) => {
  const readerCriteriaLoader =
    loadReaderCriterionForApplication(currentApplicationId);
  const [readerCriteriaLoading, readerCriteriaError, allReaderCriteria] =
    useAtomApi<ReaderCriteria[]>(
      readerCriteriaLoader,
      readerCriterionForApplicationAtom,
    );
  const readerCriteria = allReaderCriteria?.filter(
    (arc) => arc.type === 'interview',
  );

  const readerScoresLoader = loadReaderScores(currentApplicationId);
  const [readerScoresLoading, readerScoresError, readerScores] = useAtomApi<
    ReaderScore[]
  >(readerScoresLoader, readerScoresAtom);

  const [
    personApplicationsLoading,
    personApplicationsError,
    personApplications,
  ] = useAtomApi<AdminPersonApplication[]>(
    loadPersonApplicationInterviews,
    personApplicationInterviews,
  );

  type ReaderScoreMap = { [personApplicationId: string]: ReaderScore[] };
  const readerScoreMap: ReaderScoreMap =
    readerScores?.reduce((prev, curr) => {
      if (prev[curr.personApplicationId]) {
        prev[curr.personApplicationId].push(curr);
      } else {
        prev[curr.personApplicationId] = [curr];
      }
      return prev;
    }, {} as ReaderScoreMap) || {};

  const overallCriteria = readerCriteria?.find(
    (rc) => rc.title === 'Overall (Interview)',
  );
  const dataGridRows = personApplications?.map((app) => {
    let averageOverallScore;
    let numberOfCompletedInterviewers;
    let differenceInOverallScore;
    let hasOneInScore;
    const scores = readerScoreMap[app.personApplicationId];
    if (scores) {
      hasOneInScore = scores.some((s) => s.score === 1);
      if (overallCriteria) {
        const overallScores = scores
          .filter(
            (s) => s.readerCriteriaId === overallCriteria.readerCriteriaId,
          )
          .map((s) => s.score)
          .filter(Boolean) as number[];
        averageOverallScore = average(overallScores);
        if (overallScores.length === 2) {
          const [reader1Score, reader2Score] = overallScores;
          differenceInOverallScore = Math.abs(reader1Score - reader2Score);
        }
        numberOfCompletedInterviewers = overallScores.length;
      }
    }

    return {
      ...app,
      id: app.personApplicationId,
      averageOverallScore,
      numberOfCompletedReaders: numberOfCompletedInterviewers,
      differenceInOverallScore,
      hasOneInScore,
      scores: readerScoreMap[app.personApplicationId],
    };
  });

  type ScoredAppRow = AdminPersonApplication & {
    averageOverallScore?: number;
    numberOfCompletedReaders?: number;
    differenceInOverallScore?: number;
    hasOneInScore?: boolean;
    scores?: ReaderScore[];
  };
  const columns: GridColDef<ScoredAppRow>[] = [
    { field: 'id', headerName: 'Application ID', flex: 1 },
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    {
      field: 'averageOverallScore',
      headerName: 'Average Overall Score',
      flex: 1,
    },
    {
      field: 'numberOfCompletedReaders',
      headerName: '# Interviewers Overall Scores',
      flex: 1,
    },
    {
      field: 'differenceInOverallScore',
      headerName: 'Difference in Overall Score',
      flex: 1,
    },
    {
      field: 'hasOneInScore',
      headerName: 'Has 1 in Score',
      flex: 1,
      renderCell: (params) => <>{params.row.hasOneInScore ? 'Yes' : 'No'}</>,
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() =>
            NavigationController.redirectTo(
              `/admin-dashboard/view-applications/${params.row.personApplicationId}`,
            )
          }
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <DataWrapper
      loading={
        readerScoresLoading ||
        personApplicationsLoading ||
        readerCriteriaLoading
      }
      error={
        readerScoresError || personApplicationsError || readerCriteriaError
      }
    >
      {readerScores && personApplications ? (
        <DataGrid rows={dataGridRows} columns={columns} />
      ) : null}
    </DataWrapper>
  );
};
