import { atom } from 'jotai';
import { API } from '../utilities/api';

export type QuestionType =
    | 'shortText'
    | 'text'
    | 'cohortPreference'
    | 'questLearningQuestion'
    | 'referenceQuestion';

export type Question = {
    questionId: string;
    applicationId: string;
    sortOrder: number;
    questionHTML: string;
    questionType: QuestionType;
    visibleToReaders: boolean;
};

export const loadQuestions = (applicationId: string | undefined) => async () => {
    if (!applicationId) return [] as Question[];
    return API.get<Question[]>(`/questions?applicationId=${applicationId}`);
};
export const questionsAtom = atom<AtomLoadedData<Question[]>>(null);