
const supportsDataUri = () => {
  const isOldIE = navigator.appName === 'Microsoft Internet Explorer';
  const isIE11 = !!navigator.userAgent.match(/Trident\/7\./);
  return !(isOldIE || isIE11);
};

export const downloadBlob = (options: {filename: string; blob?: Blob; uri?: string}) => {
  const { blob, uri, filename } = options;
  const { msSaveOrOpenBlob } = (window.navigator as any);
  if (!blob && !uri) return;

  if (supportsDataUri()) {
    let url;
    if (blob) url = window.URL.createObjectURL(blob);
    if (uri) url = uri;
    if (!url) return;

    const pom = document.createElement('a');
    pom.setAttribute('href', url);
    pom.setAttribute('download', filename);

    if (document.createEvent) {
      const event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  } else if (msSaveOrOpenBlob && blob) {
    msSaveOrOpenBlob(blob, filename);
  } else {
    // eslint-disable-next-line no-alert
    alert('Your browser is not supported for file downloads');
  }
};
