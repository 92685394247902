import { useAtom } from 'jotai';
import { loginAtom } from '../login-page/login-page.atoms';
import { PersonForm } from './person-form';

export const PersonPage = () => {
  const [loginData] = useAtom(loginAtom);
  if (!loginData) {
    return null;
  }
  const { personId } = loginData.user;
  if (!personId) {
    // TODO: search for person by username
    return null;
  }
  return (
    <div className="u-padding">
      <h1>Registration</h1>
      <p>
        Thanks for your interest in the QUEST Honors Program! Please be sure to
        save your work in a word document in case of unexpected errors.
      </p>
      <PersonForm personId={personId} />
    </div>
  );
};
