import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { FieldValues } from 'react-hook-form';
import { FormSelect } from '../../utilities/react-hook-form-connectors/form-select';
import { Application } from '../../admin-dashboard/manage-applications/manage-applications.atoms';
import { QuestionProps } from './QuestionProps';
import { useAtomApi } from '../../utilities/use-fetch';
import {
  loadCurrentApplication,
  currentApplicationAtom,
} from '../application.atoms';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const CohortQuestion = <T extends FieldValues>({
  control,
  name,
  label,
  index,
  required,
}: QuestionProps<T>) => {
  const [
    currentApplicationLoading,
    currentApplicationError,
    currentApplication,
  ] = useAtomApi<Application>(loadCurrentApplication, currentApplicationAtom);

  if (
    !currentApplication ||
    currentApplicationLoading ||
    currentApplicationError
  ) {
    return null;
  }

  const startYear = dayjs(currentApplication.dueDate).format('YYYY');
  const secondYear = dayjs(currentApplication.dueDate)
    .add(1, 'year')
    .format('YYYY');
  const thirdYear = dayjs(currentApplication.dueDate)
    .add(2, 'year')
    .format('YYYY');
  return (
    <div className="u-margin-top">
      <div
        className="question-container"
        dangerouslySetInnerHTML={{ __html: `${index}. ${label}` }}
      ></div>
      <Table className="u-margin-top">
        <TableHead>
          <TableRow>
            <StyledTableCell>Semester</StyledTableCell>
            <StyledTableCell>
              Cohort {currentApplication.cohorts[0]}
            </StyledTableCell>
            <StyledTableCell>
              Cohort {currentApplication.cohorts[1]}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <TableCell>
              <b>Key Difference</b>
            </TableCell>
            <TableCell>
              <b>Starts in Fall</b>
            </TableCell>
            <TableCell>
              <b>Starts in Spring</b>
            </TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>Fall {startYear}</TableCell>
            <TableCell>BMGT/ENES 190H</TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>Spring {secondYear}</TableCell>
            <TableCell>BMGT 438A/ENES 489A</TableCell>
            <TableCell>BMGT/ENES 190H</TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>Fall {secondYear}</TableCell>
            <TableCell>BMGT/ENES 390H</TableCell>
            <TableCell>BMGT 438A/ENES 489A</TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>Spring {thirdYear}</TableCell>
            <TableCell>BMGT/ENES 490H</TableCell>
            <TableCell>BMGT/ENES 390H</TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>Fall {thirdYear}</TableCell>
            <TableCell></TableCell>
            <TableCell>BMGT/ENES 490H</TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
      <p>
        <b>IMPORTANT!</b> Think carefully about your four-year plan and select
        your preferred cohort based on when you would be required to take these
        courses. If you are planning to study abroad and/or co-op, you will have
        an opportunity to change the semester that you take one of our middle
        two courses: either 438A/489A or 390H. However, you cannot change the
        semester that you take 190H or 490H.
      </p>
      <p>
        <b>Note:</b> Once you select a cohort, your choice is binding. Students
        cannot switch between cohorts.
        <br />
        <b>Note:</b> Your selection will have no bearing on whether or not you
        are admitted. This will be considered only after an admission decision
        has been made.
      </p>
      <FormSelect control={control} name={name} required={required}>
        <MenuItem value={`${currentApplication.cohorts[0]}-only`}>
          I prefer cohort {currentApplication.cohorts[0]} only (begins class in
          Fall {startYear})
        </MenuItem>
        <MenuItem value={`${currentApplication.cohorts[1]}-only`}>
          I prefer cohort {currentApplication.cohorts[1]} only (begins class in
          Spring {secondYear})
        </MenuItem>
        <MenuItem value={`${currentApplication.cohorts[0]}-and`}>
          I prefer cohort {currentApplication.cohorts[0]}, but would join{' '}
          {currentApplication.cohorts[1]}
        </MenuItem>
        <MenuItem value={`${currentApplication.cohorts[1]}-and`}>
          I prefer cohort {currentApplication.cohorts[1]}, but would join{' '}
          {currentApplication.cohorts[0]}
        </MenuItem>
        <MenuItem value="no-preference">No Preference</MenuItem>
      </FormSelect>
    </div>
  );
};
