import { atom } from 'jotai';
import { API } from '../../utilities/api';

export type ReaderCriteria = {
  readerCriteriaId: string;
  applicationId: string;
  sortOrder: number;
  title: string;
  criteriaHTML: string;
  createdAt: Date;
  type: 'reader' | 'interview';
};

export type ReaderAssignment = {
  readerAssignmentId: string;
  readerId: string;
  readerUsername: string;
  personApplicationId: string;
  major: string | null;
  submittedAt: string | null;
};

export type ReaderScore = {
  readerScoreId: string;
  readerId: string;
  readerUsername: string;
  readerCriteriaId: string;
  readerCriteriaTitle: string;
  readerCriteriaType: string;
  personApplicationId: string;
  score: number | null;
  comment: string | null;
  createdAt: Date | null;
};

export const loadReaderCriterionForApplication =
  (applicationId: string) => async () => {
    return API.get<ReaderCriteria[]>(
      `/reader-criteria?applicationId=${applicationId}`,
    );
  };
export const readerCriterionForApplicationAtom =
  atom<AtomLoadedData<ReaderCriteria[]>>(null);

export const loadReaderAssignments = () => async () => {
  return API.get<ReaderAssignment[]>(`/reader-assignments`);
};
export const readerAssignmentsAtom =
  atom<AtomLoadedData<ReaderAssignment[]>>(null);

export const loadReaderAssignmentsForApplication =
  (personApplicationId: string) => async () => {
    return API.get<ReaderAssignment[]>(
      `/reader-assignments?personApplicationId=${personApplicationId}`,
    );
  };
export const readerAssignmentsForApplicationAtom =
  atom<AtomLoadedData<ReaderAssignment[]>>(null);

export const loadReaderScores = (applicationId: string) => async () => {
  return API.get<ReaderScore[]>(
    `/reader-scores?applicationId=${applicationId}`,
  );
};
export const readerScoresAtom = atom<AtomLoadedData<ReaderScore[]>>(null);

export const loadReaderScoresForApplication =
  (personApplicationId: string) => async () => {
    return API.get<ReaderScore[]>(
      `/reader-scores?personApplicationId=${personApplicationId}`,
    );
  };
export const readerScoresForApplicationAtom =
  atom<AtomLoadedData<ReaderScore[]>>(null);
