import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

type FormSelectProps<T extends FieldValues> = UseControllerProps<T> & {
  label?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
};

export const FormCheckbox = <T extends FieldValues>({
  name,
  label,
  control,
  required,
  className,
  rules,
  disabled,
}: FormSelectProps<T>) => (
  <Controller
    name={name}
    control={control}
    rules={{
      ...rules,
      required: required || rules?.required ? 'Required' : false,
    }}
    render={({ field, fieldState: { error } }) => (
      <FormControl required error={Boolean(error)} className={className}>
        <FormControlLabel
          control={
            <Checkbox
              checked={field.value}
              disabled={disabled}
              {...field}
            />
          }
          label={label}
        />
        <FormHelperText>{error?.message}</FormHelperText>
      </FormControl>
    )}
  />
);
