import { atom } from 'jotai';
import { API } from '../utilities/api';

export type Person = {
    personId: string;
    firstName: string;
    lastName: string;
    email: string | null;
    phoneNumber: string | null;
    preferredName: string | null;
    gender: string | null;
    pronouns: string | null;
    race: string | null;
    questRequirementCompletedSemester: boolean;
    questRequirementCurrentFreshman: boolean;
    questRequirementGpa: boolean;
    questRequirementCurrentlyEnrolled: boolean;
    questRequirementExtenuatingCircumstances: string | null;
    gpa: string | null;
    firstGenerationCollegeStudent: string | null;
    creditsCompletedAtUMD: string | null;
    plannedGraduatingYear: string | null;
    major: string[];
    honorsProgram: string[];
    freshmanConnection: boolean;
};

export const loadPerson = (personId: string | undefined) => async () => {
    if (!personId) return null;
    return API.get<Person>(`/people/${personId}`);
};
export const personAtom = atom<AtomLoadedData<Person>>(null);