import { FieldValues, Path } from 'react-hook-form';
import { FormCheckbox } from '../../utilities/react-hook-form-connectors/form-checkbox';
import { QuestionProps } from './QuestionProps';

export const LearningOptions = [
  'Advisor',
  'Social Media',
  'First Look Fair/ClarkLEAD/General Tabling',
  'Information Session',
  'Speaker in Class',
  'Talented Student Email',
  'Other Email',
  'UMD Admitted Student Day',
  'Word of Mouth',
  'Other',
];
export const getLearningName = <T extends FieldValues>(
  questionId: Path<T> | string,
  option: string,
): Path<T> => `${questionId}-${option}` as Path<T>;

export const QuestLearningQuestion = <T extends FieldValues>({
  control,
  name,
  label,
  index,
}: QuestionProps<T>) => {
  return (
    <div className="u-margin-top">
      <div
        className="question-container"
        dangerouslySetInnerHTML={{ __html: `${index}. ${label}` }}
      ></div>
      {LearningOptions.map((option) => (
        <div className="u-margin-left-xl" key={option}>
          <FormCheckbox
            control={control}
            name={getLearningName(name, option)}
            label={option}
          />
        </div>
      ))}
    </div>
  );
};
