import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { loginAtom } from '../login-page/login-page.atoms';
import { DataWrapper } from '../utilities/data-wrapper';
import { useAtomApi } from '../utilities/use-fetch';
import { ApplicationForm } from './application-form';
import {
  Application,
  currentApplicationAtom,
  loadCurrentApplication,
} from './application.atoms';

export const ApplicationPage = () => {
  const [
    currentApplicationLoading,
    currentApplicationError,
    currentApplication,
  ] = useAtomApi<Application>(loadCurrentApplication, currentApplicationAtom);

  const [loginData] = useAtom(loginAtom);
  if (!loginData) {
    return null;
  }
  const { personId } = loginData.user;
  if (!personId) {
    // TODO: search for person by username
    return null;
  }

  const isClosed = currentApplication
    ? dayjs(currentApplication.dueDate).add(7, 'hours').isBefore(dayjs())
    : false;

  return (
    <DataWrapper
      loading={currentApplicationLoading}
      error={currentApplicationError}
    >
      <div className="u-padding">
        {currentApplication ? (
          <>
            <div>
              <h2>Thanks, and Good Luck From QUEST!</h2>
              <p>
                Thank you for your interest in the QUEST Honors Program! We are
                glad that you are applying to be a part of the program.
              </p>
              <p>
                Please fill out the application below. We recommend that you
                compose your answers in a word processing program and copy and
                paste them into this form.
              </p>
              <p>
                Press "Save" to store your answers in our database, and once you
                are done with the application, please be sure to click the
                "Submit" button. After submitting, you will be able to edit your
                answers up until the deadline.
              </p>
              <p>
                If you have questions as you fill out the application, please
                contact QUEST at{' '}
                <a href="mailto:questhonors@umd.edu">questhonors@umd.edu</a>.
                This application will be available until{' '}
                {dayjs(currentApplication.dueDate).format('dddd, MMM D, YYYY')}{' '}
                at 11:59 pm.
              </p>
            </div>
            <ApplicationForm
              applicationId={currentApplication.applicationId}
              isClosed={isClosed}
              personId={personId}
            />
          </>
        ) : null}
      </div>
    </DataWrapper>
  );
};
