import { useAtom } from 'jotai';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { loginAtom } from '../login-page/login-page.atoms';
import {
  IsInterviewer,
  isInterviewerAtom,
  loadIsInterview,
} from './scoring-page/reader-criteria.atoms';
import { useAtomApi } from '../utilities/use-fetch';
import { DataWrapper } from '../utilities/data-wrapper';

const PATH_TO_TAB_CONFIG = {
  '/reader-dashboard/applications': 0,
  '/reader-dashboard/application': 0,
  '/reader-dashboard/interviews': 1,
};
const TAB_TO_PATH_CONFIG = [
  '/reader-dashboard/applications',
  '/reader-dashboard/interviews',
];

export const ReaderDashboard = () => {
  const [loginData] = useAtom(loginAtom);
  const navigate = useNavigate();
  const location = useLocation();

  const isInterviewLoader = loadIsInterview(loginData?.user.username);
  const [isInterviewLoading, isInterviewError, isInterviewer] =
    useAtomApi<IsInterviewer>(
      isInterviewLoader,
      isInterviewerAtom,
      (response) => {
        if (response.isInterviewer) {
          navigate(TAB_TO_PATH_CONFIG[1]);
        }
      },
    );

  const tabConfigKey = Object.keys(PATH_TO_TAB_CONFIG).find((val) => {
    return location.pathname.includes(val);
  });
  const tabIndex = tabConfigKey
    ? PATH_TO_TAB_CONFIG[tabConfigKey as keyof typeof PATH_TO_TAB_CONFIG] ?? 0
    : 0;

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    navigate(TAB_TO_PATH_CONFIG[newValue]);
  };

  if (loginData?.user.role !== 'reader') {
    navigate('/');
    return null;
  }

  return (
    <div className="with-sidebar u-height-100">
      <DataWrapper loading={isInterviewLoading} error={isInterviewError}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabIndex}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Read Applications" />
          <Tab
            label="Interviews"
            className={`${
              !isInterviewer?.isInterviewer ? 'u-display-none' : ''
            }`}
          />
        </Tabs>
      </DataWrapper>
      <Outlet />
    </div>
  );
};
