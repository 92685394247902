import { Link, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import {
  Application,
  applicationsAtom,
  loadApplications,
} from './manage-applications.atoms';

import {
  questionsAtom,
  loadQuestions,
  Question,
} from '../../questions/questions.atoms';

import { QuestionEditorDialog } from './question-editor-dialog';

import { useAtomApi } from '../../utilities/use-fetch';
import { DataWrapper } from '../../utilities/data-wrapper';
import { FormTextField } from '../../utilities/react-hook-form-connectors/form-text-field';
import { API } from '../../utilities/api';
import { FormDatePicker } from '../../utilities/react-hook-form-connectors/form-date-picker';
import { DeleteButton } from '../../utilities/delete-button';

export const ManageApplicationPage = () => {
  const { applicationId } = useParams();
  const [applicationLoading, applicationsError, apps, setApps] = useAtomApi<
    Application[]
  >(loadApplications, applicationsAtom);
  const [questionLoading, questionError, qs, setQuestions] = useAtomApi<
    Question[]
  >(loadQuestions(applicationId), questionsAtom);
  const app = apps?.find((app) => app.applicationId === applicationId);

  if (!applicationId || (!app && apps)) {
    return null;
  }

  const ApplicationBreadcrumb = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin-dashboard/manage-applications">All Applications</Link>
        <Typography color="text.primary">{applicationId}</Typography>
      </Breadcrumbs>
    );
  };

  const onApplicationEdit = (updatedApplication: Application) => {
    const appIndex = apps.findIndex(
      (app) => app.applicationId === updatedApplication.applicationId,
    );
    if (appIndex < 0) {
      apps.push(updatedApplication);
    } else {
      apps[appIndex] = updatedApplication;
    }
    setApps(apps.slice());
  };

  const onQuestionEdit = (updatedQuestion: Question) => {
    const questionIndex = qs.findIndex(
      (q) => q.questionId === updatedQuestion.questionId,
    );
    if (questionIndex < 0) {
      qs.push(updatedQuestion);
    } else {
      qs[questionIndex] = updatedQuestion;
    }
    setQuestions(qs.slice());
  };

  const onQuestionDelete = (questionId: string) => {
    setQuestions(qs.filter((q) => q.questionId !== questionId).slice());
  };

  const loading = applicationLoading && questionLoading;
  const error =
    applicationsError || questionError
      ? {
          ...applicationsError,
          ...questionError,
        }
      : null;

  return (
    <DataWrapper loading={loading} error={error}>
      <div className="u-padding-xl u-width-100">
        <ApplicationBreadcrumb />
        {app ? (
          <ManageApplicationFormPage
            app={app}
            questions={qs || []}
            onApplicationEdit={onApplicationEdit}
            onQuestionEdit={onQuestionEdit}
            onQuestionDelete={onQuestionDelete}
          />
        ) : null}
      </div>
    </DataWrapper>
  );
};

type ManageApplicationFormPageProps = {
  app: Application;
  questions: Question[];
  onApplicationEdit: (updatedApplication: Application) => void;
  onQuestionEdit: (updatedQuestion: Question) => void;
  onQuestionDelete: (questionId: string) => void;
};

const ManageApplicationFormPage = ({
  app,
  questions,
  onApplicationEdit,
  onQuestionEdit,
  onQuestionDelete,
}: ManageApplicationFormPageProps) => {
  const defaultValues = {
    applicationName: app.applicationName ?? '',
    dueDate: app.dueDate ?? '',
    lowCohort: app.cohorts[0] ?? '',
    highCohort: app.cohorts[1] ?? '',
  };

  const {
    control,
    // setValue,
    formState: { isDirty, errors },
    handleSubmit,
    // watch,
    reset,
    // trigger,
  } = useForm({ defaultValues });

  const hasErrors = Object.keys(errors).length > 0;

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    const updatedApp = await API.post<Application>(
      `/applications/${app.applicationId}`,
      data,
    );
    reset(data);
    onApplicationEdit(updatedApp);
  };

  const questionColumns: GridColDef<Question>[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'sortOrder', headerName: 'Sort Order', flex: 1 },
    {
      field: 'questionHTML',
      headerName: 'Question Text',
      renderCell: (params) => (
        <div
          className="u-padding"
          dangerouslySetInnerHTML={{ __html: params.row.questionHTML }}
        />
      ),
      flex: 5,
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      flex: 1,
      renderHeader: () => (
        <QuestionEditorDialog
          isCreate
          onSave={onQuestionEdit}
          applicationId={app.applicationId}
        />
      ),
      renderCell: (params) => (
        <>
          <QuestionEditorDialog
            question={params.row}
            onSave={onQuestionEdit}
            applicationId={app.applicationId}
          />
          <DeleteButton
            id={params.row.questionId}
            path="/questions/"
            name="question"
            onDelete={onQuestionDelete}
          />
        </>
      ),
    },
  ];

  const questionDataRows = questions
    .map((q) => ({
      id: q.questionId,
      ...q,
    }))
    .sort((q1, q2) => q1.sortOrder - q2.sortOrder);

  return (
    <Paper className="u-margin-top u-padding">
      <span className="typo-h6">Application Configuration</span>
      <div className="u-margin-top">
        <FormTextField
          control={control}
          name="applicationName"
          label="Application Name"
        />
        <FormDatePicker
          control={control}
          name="dueDate"
          label="Due Date"
          className="u-margin-top"
        />

        <div className="u-spread u-margin-top">
          <FormTextField
            control={control}
            name="lowCohort"
            label="Lower Cohort Bound"
            className="u-margin-right"
          />
          -
          <FormTextField
            control={control}
            name="highCohort"
            label="Upper Cohort Bound"
            className="u-margin-left"
          />
        </div>
      </div>
      <div className="u-width-100 u-spread u-margin-top">
        <span className="typo-h6">Questions</span>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={hasErrors || !isDirty}
          className="u-margin-bottom"
        >
          Save
        </Button>
      </div>
      <div className="u-height-450px">
        <DataGrid rows={questionDataRows} columns={questionColumns} />
      </div>
    </Paper>
  );
};
