import { useAtom } from 'jotai';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { loginAtom } from '../login-page/login-page.atoms';

const PATH_TO_TAB_CONFIG = {
  '/applicant-dashboard/profile': 0,
  '/applicant-dashboard/application': 1,
};
const TAB_TO_PATH_CONFIG = [
  '/applicant-dashboard/profile',
  '/applicant-dashboard/application',
];

export const ApplicantDashboard = () => {
  const [loginData] = useAtom(loginAtom);
  const navigate = useNavigate();
  const location = useLocation();

  const tabConfigKey = Object.keys(PATH_TO_TAB_CONFIG).find((val) =>
    location.pathname.includes(val),
  );

  const tabIndex = tabConfigKey
    ? PATH_TO_TAB_CONFIG[tabConfigKey as keyof typeof PATH_TO_TAB_CONFIG] ?? 0
    : 0;

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    navigate(TAB_TO_PATH_CONFIG[newValue]);
  };

  if (loginData?.user.role !== 'applicant') {
    navigate('/');
    return null;
  }

  return (
    <div className="with-sidebar u-height-100">
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabIndex}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Profile" />
        <Tab label="Application" />
      </Tabs>
      <Outlet />
    </div>
  );
};
