import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { yellow } from "@mui/material/colors";

import type {} from '@mui/x-date-pickers/themeAugmentation';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#b71c1c",
    },
    secondary: yellow,
    error: {
      main: red.A400,
    },
  },
});
