import { FieldValues, Path } from 'react-hook-form';
import { FormTextField } from '../../utilities/react-hook-form-connectors/form-text-field';
import { QuestionProps } from './QuestionProps';

export const ReferenceQuestion = <T extends FieldValues>({
  control,
  name,
  label,
  index,
  required,
}: QuestionProps<T>) => {
  return (
    <div className="u-margin-top">
      <div
        className="question-container"
        dangerouslySetInnerHTML={{
          __html: `${index}. ${label}`,
        }}
      ></div>
      <FormTextField
        control={control}
        name={`${name}-name` as Path<T>}
        label="Name"
        className="u-margin-top"
        required={required}
      />
      <FormTextField
        control={control}
        name={`${name}-email` as Path<T>}
        label="Email"
        className="u-margin-top"
        required={required}
      />
      <FormTextField
        control={control}
        name={`${name}-phone` as Path<T>}
        label="Phone Number"
        className="u-margin-top"
        required={required}
      />
    </div>
  );
};
