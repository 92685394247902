import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

type FormSelectProps<T extends FieldValues> = UseControllerProps<T> & {
  label?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
};

export const FormDatePicker = <T extends FieldValues>({
  name,
  label,
  control,
  required,
  className,
  rules,
  disabled,
}: FormSelectProps<T>) => (
  <Controller
    name={name}
    control={control}
    rules={{
      ...rules,
      required: required || rules?.required ? 'Required' : false,
    }}
    render={({ field, fieldState: { error } }) => (
      <>
        <DesktopDatePicker
          label={label}
          disabled={disabled}
          inputFormat="MM/DD/YYYY"
          className={className}
          {...field}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
        <FormHelperText>{error?.message}</FormHelperText>
      </>
    )}
  />
);
