import { ReactNode } from 'react';

type InputProps = {
  loading: boolean;
  error?: { message?: string };
  children: ReactNode;
};

export const DataWrapper = ({ loading, error, children }: InputProps) => {
  return (
    <>
      {loading ? <div>loading...</div> : null}
      {error ? <div>{error.message}</div> : null}
      {!loading && !error ? children : null}
    </>
  );
};
