import { atom } from 'jotai';
import { API } from '../../utilities/api';

export type Application = {
    applicationId: string;
    applicationName: string;
    dueDate: Date;
    cohorts: Array<string>;
}

export const loadApplications = async () => {
    return API.get<Application[]>('/applications');
};
export const applicationsAtom = atom<AtomLoadedData<Application[]>>(null);
