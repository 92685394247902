import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useAtom } from 'jotai';
import { SetStateAction, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { ViewApplicationPageComponent } from '../../admin-dashboard/view-applications/view-application-page';
import {
  Application,
  loadCurrentApplication,
  currentApplicationAtom,
  answersAtom,
} from '../../application/application.atoms';
import { personAtom } from '../../application/person.atoms';
import { loginAtom } from '../../login-page/login-page.atoms';
import { API } from '../../utilities/api';
import { DataWrapper } from '../../utilities/data-wrapper';
import { FormSelect } from '../../utilities/react-hook-form-connectors/form-select';
import { FormTextField } from '../../utilities/react-hook-form-connectors/form-text-field';
import { useAtomApi } from '../../utilities/use-fetch';
import {
  loadReaderCriterionForApplication,
  ReaderCriteria,
  readerCriterionForApplicationAtom,
  loadReaderAssignments,
  ReaderAssignment,
  readerAssignmentsAtom,
  loadReaderScores,
  ReaderScore,
  readerScoresAtom,
} from './reader-criteria.atoms';

export const AssignedApplication = () => {
  const [
    currentApplicationLoading,
    currentApplicationError,
    currentApplication,
  ] = useAtomApi<Application>(loadCurrentApplication, currentApplicationAtom);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_currAnswers, setCurrAnswers] = useAtom(answersAtom);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_personValue, setPersonValue] = useAtom(personAtom);
  useEffect(() => {
    setCurrAnswers(null);
    setPersonValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loginData] = useAtom(loginAtom);
  if (!loginData) {
    return null;
  }
  const { userId } = loginData.user;
  if (!userId) {
    return null;
  }

  return (
    <DataWrapper
      loading={currentApplicationLoading}
      error={currentApplicationError}
    >
      {currentApplication ? (
        <ReaderDataLoader
          applicationId={currentApplication.applicationId}
          readerId={userId}
        />
      ) : null}
    </DataWrapper>
  );
};

type ReaderCriteriaLoaderProps = { applicationId: string; readerId: string };

const ReaderDataLoader = ({
  applicationId,
  readerId,
}: ReaderCriteriaLoaderProps) => {
  const { readerAssignmentId } = useParams();

  const readerCriteriaLoader = loadReaderCriterionForApplication(applicationId);
  const [readerCriteriaLoading, readerCriteriaError, readerCriteria] =
    useAtomApi<ReaderCriteria[]>(
      readerCriteriaLoader,
      readerCriterionForApplicationAtom,
    );

  const readerAssignmentLoader = loadReaderAssignments(readerId, applicationId);
  const [
    readerAssignmentLoading,
    readerAssignmentError,
    readerAssignments,
    setReaderAssignments,
  ] = useAtomApi<ReaderAssignment[]>(
    readerAssignmentLoader,
    readerAssignmentsAtom,
  );

  const readerScoresLoader = loadReaderScores(readerId, applicationId);
  const [
    readerScoresLoading,
    readerScoresError,
    readerScores,
    setReaderScores,
  ] = useAtomApi<ReaderScore[]>(readerScoresLoader, readerScoresAtom);

  return (
    <DataWrapper
      loading={
        readerCriteriaLoading || readerAssignmentLoading || readerScoresLoading
      }
      error={readerCriteriaError || readerAssignmentError || readerScoresError}
    >
      {readerCriteria &&
      readerAssignments &&
      readerScores &&
      readerAssignmentId ? (
        <AssignedApplicationView
          readerId={readerId}
          applicationId={applicationId}
          readerCriteria={readerCriteria}
          readerAssignmentId={readerAssignmentId}
          readerAssignments={readerAssignments}
          readerScores={readerScores}
          setReaderScores={setReaderScores}
          setReaderAssignments={setReaderAssignments}
        />
      ) : null}
    </DataWrapper>
  );
};

const getDefaultValues = (
  readerCriteria: ReaderCriteria[],
  readerScores: ReaderScore[],
) => {
  return (
    readerCriteria?.reduce((acc, curr) => {
      const response: ReaderScore | undefined = readerScores.find(
        (rs) => rs.readerCriteriaId === curr.readerCriteriaId,
      );
      if (response) {
        return {
          ...acc,
          [`${curr.readerCriteriaId}-score`]: String(response.score),
          [`${curr.readerCriteriaId}-comment`]: response.comment,
        };
      } else {
        return {
          ...acc,
          [`${curr.readerCriteriaId}-score`]: '',
          [`${curr.readerCriteriaId}-comment`]: '',
        };
      }
    }, {} as Record<string, any>) || {}
  );
};

type AssignedApplicationProp = {
  readerId: string;
  applicationId: string;
  readerCriteria: ReaderCriteria[];
  readerAssignmentId?: string;
  readerAssignments?: ReaderAssignment[];
  personApplicationId?: string;
  readerScores: ReaderScore[];
  setReaderScores: (update: SetStateAction<ReaderScore[]>) => void;
  setReaderAssignments?: (update: SetStateAction<ReaderAssignment[]>) => void;
  onScoreSave?: () => void;
};

type ScoresToShowProps = {
  readerScores: ReaderScore[];
  readerCriteria: ReaderCriteria[];
  showOnlyTitles?: boolean;
};

export const ScoresToShow = ({
  readerScores,
  readerCriteria,
  showOnlyTitles,
}: ScoresToShowProps) => {
  const answerComponents = readerCriteria.map((rc, index) => {
    const thisReaderScore = readerScores?.find(
      (rs) => rs.readerCriteriaId === rc.readerCriteriaId,
    );
    if (!thisReaderScore) {
      return null;
    }
    let score = thisReaderScore.score || '';
    let comment = thisReaderScore.comment || '';
    return (
      <TableRow key={thisReaderScore.readerScoreId}>
        <TableCell component="th" scope="row" className="u-width-50">
          <b
            className="question-container"
            dangerouslySetInnerHTML={{
              __html: `${index + 1}. ${
                showOnlyTitles ? rc.title : rc.criteriaHTML
              }`,
            }}
          ></b>
        </TableCell>
        <TableCell>{score}</TableCell>
        <TableCell>{comment}</TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer component={Paper} className="u-margin-top">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>{answerComponents}</TableBody>
      </Table>
    </TableContainer>
  );
};

export const AssignedApplicationView = ({
  readerId,
  applicationId,
  readerCriteria,
  readerAssignmentId,
  readerAssignments,
  personApplicationId,
  readerScores,
  setReaderScores,
  setReaderAssignments,
  onScoreSave,
}: AssignedApplicationProp) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const readerAssignment = readerAssignments?.find(
    (ra) => ra.readerAssignmentId === readerAssignmentId,
  );

  const theseScores = readerScores.filter(
    (rs) =>
      rs.personApplicationId === readerAssignment?.personApplicationId ||
      rs.personApplicationId === personApplicationId,
  );

  const defaultValues = getDefaultValues(readerCriteria, theseScores);
  const {
    control,
    // setValue,
    formState: { errors },
    handleSubmit,
    getValues,
    // watch,
    reset,
    // trigger,
  } = useForm({ defaultValues, mode: 'all' });

  if (readerAssignments && !readerAssignment) {
    return null;
  }

  const thisPersonApplicationId =
    readerAssignment?.personApplicationId || personApplicationId;
  if (!thisPersonApplicationId) {
    return null;
  }

  const onSave = () => {
    const values = getValues();
    onSubmit(false)(values);
  };

  const onSubmit: (isSubmit: boolean) => SubmitHandler<typeof defaultValues> =
    (isSubmit: boolean) => async (data) => {
      setIsLoading(true);

      let requestObj: {
        [key: string]: Partial<ReaderScore>;
      } = {};
      Object.keys(data).forEach((key) => {
        if (!data[key]) {
          return;
        }
        const [readerCriteriaId, type] = key.split('-');
        const currentScore: ReaderScore | undefined = theseScores.find(
          (rs) => rs.readerCriteriaId === readerCriteriaId,
        );
        requestObj[readerCriteriaId] = {
          ...(requestObj[readerCriteriaId] ?? {}),
          [type]: data[key],
          readerId,
          readerCriteriaId: readerCriteriaId,
          personApplicationId: thisPersonApplicationId,
          readerScoreId: currentScore?.readerScoreId,
        };
      });

      const response = await API.post<ReaderScore[]>(
        '/reader-scores/bulk-update',
        Object.values(requestObj),
      );

      const otherScores = readerScores.filter(
        (rs) => rs.personApplicationId !== thisPersonApplicationId,
      );

      if (
        isSubmit &&
        readerAssignments &&
        setReaderAssignments &&
        readerAssignment
      ) {
        const newReaderAssignment = {
          ...readerAssignment,
          submittedAt: new Date().toISOString(),
        };
        await API.post<ReaderAssignment>(
          `/reader-assignments/${readerAssignment.readerAssignmentId}`,
          newReaderAssignment,
        );

        const otherReaderAssignments = readerAssignments.filter(
          (ra) => ra.readerAssignmentId !== readerAssignmentId,
        );
        otherReaderAssignments.push(newReaderAssignment);

        setReaderAssignments(otherReaderAssignments);
      }

      setIsLoading(false);
      setReaderScores(response.concat(otherScores));
      reset(getDefaultValues(readerCriteria, response));

      if (onScoreSave) {
        onScoreSave();
      }
    };

  const ReaderBreadcrumb = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/reader-dashboard/applications">All Applications</Link>
        <Typography color="text.primary">{thisPersonApplicationId}</Typography>
      </Breadcrumbs>
    );
  };

  const sortedCriteria =
    readerCriteria.sort((a, b) => a.sortOrder - b.sortOrder) || [];

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <div className="u-padding">
      {readerAssignment ? <ReaderBreadcrumb /> : null}
      <ViewApplicationPageComponent
        applicationId={applicationId}
        personApplicationId={thisPersonApplicationId}
        respectVisibleToReaders
      />
      <h1 className="u-margin-top">
        {readerAssignment ? 'Application' : 'Interview'} Scores
      </h1>
      <Paper>
        <div className="u-padding">
          {readerAssignment?.submittedAt ? (
            <ScoresToShow
              readerScores={theseScores}
              readerCriteria={sortedCriteria}
            />
          ) : (
            sortedCriteria.map((rc, index) => (
              <div className="u-margin-top" key={rc.readerCriteriaId}>
                <h1>{rc.title}</h1>
                <hr />
                <div
                  className="question-container"
                  dangerouslySetInnerHTML={{
                    __html: `${index + 1}. ${rc.criteriaHTML}`,
                  }}
                ></div>
                <div className="u-padding-top u-maxWidth-500px">
                  <FormSelect
                    control={control}
                    name={`${rc.readerCriteriaId}-score`}
                    label="Score"
                    required
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                  </FormSelect>
                  <FormTextField
                    control={control}
                    name={`${rc.readerCriteriaId}-comment`}
                    label="Comments"
                    multiline
                    rows={4}
                  />
                </div>
              </div>
            ))
          )}
        </div>
        {!readerAssignment?.submittedAt ? (
          <div className="u-padding">
            <Button variant="contained" disabled={isLoading} onClick={onSave}>
              {readerAssignment ? 'Save' : 'Submit'}
            </Button>
            {readerAssignment ? (
              <Button
                variant="contained"
                disabled={
                  isLoading ||
                  hasErrors ||
                  Boolean(readerAssignment.submittedAt)
                }
                onClick={handleSubmit(onSubmit(true))}
                className="u-margin-left"
              >
                Submit
              </Button>
            ) : null}
          </div>
        ) : null}
      </Paper>
    </div>
  );
};
