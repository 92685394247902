import {
  Button,
  Paper,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { loginAtom, loginRequest, LoginResponse } from './login-page.atoms';
import { CasUtility } from '../utilities/cas-utility';
import { API } from '../utilities/api';

type PageOption = 'options' | 'login' | 'register';

export const LoginPage = () => {
  const [page, setPage] = useState<PageOption>('options');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loginData, setLoginData] = useAtom(loginAtom);

  const navigate = useNavigate();

  if (loginData?.user.role === 'admin') {
    navigate('/admin-dashboard/manage-applications');
  }
  if (loginData?.user.role === 'applicant') {
    navigate('/applicant-dashboard/profile');
  }
  if (loginData?.user.role === 'reader') {
    navigate('/reader-dashboard/applications');
  }

  const handleChangePage = (page: PageOption) => () => {
    setUsername('');
    setPassword('');
    setFirstName('');
    setLastName('');
    setErrorMessage('');
    setPage(page);
  };
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginClick = async () => {
    if (!username || !password) {
      return;
    }
    const loginData = await loginRequest(username, password);
    if ((loginData as any).statusCode === 401) {
      setErrorMessage('Access Denied');
    } else {
      setLoginData(loginData);
    }
  };

  const handleRegisterClick = async () => {
    if (!username || !password || !firstName || !lastName) {
      return;
    }
    const loginData = await API.post<LoginResponse>('/auth/register', {
      username,
      password,
      firstName,
      lastName,
    });
    if ((loginData as any).message) {
      setErrorMessage('Email is already in use. Please use another.');
    } else if ((loginData as any).statusCode === 401) {
      setErrorMessage('Access Denied');
    } else {
      setLoginData(loginData);
    }
  };

  const handleChange =
    (handler: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage('');
      handler(event.target.value);
    };

  const handleCasLogin = () => {
    CasUtility.login();
  };

  return (
    <div className="u-display-flex u-justifyContent-center u-alignItems-center u-width-100 u-margin-top-xl">
      <Paper className="u-width-400px u-padding-xl u-flexColumn">
        {page === 'options' ? (
          <>
            <Button variant="contained" size="large" onClick={handleCasLogin}>
              UMD Login
            </Button>
            <Button
              variant="contained"
              size="large"
              className="u-margin-top"
              onClick={handleChangePage('register')}
            >
              Register
            </Button>
            <Button
              variant="contained"
              size="large"
              className="u-margin-top"
              color="secondary"
              onClick={handleChangePage('login')}
            >
              Login
            </Button>
          </>
        ) : null}
        {page === 'login' ? (
          <>
            <FormControl variant="outlined">
              <InputLabel>Email</InputLabel>
              <OutlinedInput
                value={username}
                onChange={handleChange(setUsername)}
                endAdornment={
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                }
                label="Email"
              />
            </FormControl>
            <FormControl variant="outlined" className="u-margin-top">
              <InputLabel>Password</InputLabel>
              <OutlinedInput
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handleChange(setPassword)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPasswordClick} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {errorMessage ? (
              <div className="u-color-red">{errorMessage}</div>
            ) : null}
            <Button
              variant="contained"
              size="large"
              className="u-margin-top"
              onClick={handleLoginClick}
            >
              Login
            </Button>
            <Button
              size="large"
              className="u-margin-top"
              onClick={handleChangePage('options')}
            >
              Cancel
            </Button>
          </>
        ) : null}
        {page === 'register' ? (
          <>
            <FormControl variant="outlined">
              <InputLabel>First Name</InputLabel>
              <OutlinedInput
                value={firstName}
                onChange={handleChange(setFirstName)}
                endAdornment={
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                }
                label="First Name"
              />
            </FormControl>
            <FormControl variant="outlined" className="u-margin-top">
              <InputLabel>Last Name</InputLabel>
              <OutlinedInput
                value={lastName}
                onChange={handleChange(setLastName)}
                endAdornment={
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                }
                label="Last Name"
              />
            </FormControl>
            <FormControl variant="outlined" className="u-margin-top">
              <InputLabel>Email</InputLabel>
              <OutlinedInput
                value={username}
                onChange={handleChange(setUsername)}
                endAdornment={
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                }
                label="Email"
              />
            </FormControl>
            <FormControl variant="outlined" className="u-margin-top">
              <InputLabel>Password</InputLabel>
              <OutlinedInput
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handleChange(setPassword)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPasswordClick} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {errorMessage ? (
              <div className="u-color-red">{errorMessage}</div>
            ) : null}
            <Button
              variant="contained"
              size="large"
              className="u-margin-top"
              onClick={handleRegisterClick}
              disabled={!firstName || !lastName || !username || !password}
            >
              Register
            </Button>
            <Button
              size="large"
              className="u-margin-top"
              onClick={handleChangePage('options')}
            >
              Cancel
            </Button>
          </>
        ) : null}
      </Paper>
    </div>
  );
};
