import { Alert, Button, Snackbar } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { DataWrapper } from '../utilities/data-wrapper';
import { useAtomApi } from '../utilities/use-fetch';
import {
  Question,
  loadQuestions,
  questionsAtom,
} from '../questions/questions.atoms';
import { CohortQuestion } from './question-components/cohort-question';
import {
  getLearningName,
  LearningOptions,
  QuestLearningQuestion,
} from './question-components/quest-learning-question';
import { ReferenceQuestion } from './question-components/reference-question';
import { ShortTextQuestion } from './question-components/short-text-question';
import { TextQuestion } from './question-components/text-question';
import {
  Answer,
  answersAtom,
  loadAnswersByPersonApplicationId,
  loadPersonApplicationByPersonId,
  PersonApplication,
  personApplicationAtom,
  PersonApplicationRequest,
} from './application.atoms';
import { useEffect, useRef, useState } from 'react';
import { API } from '../utilities/api';
import { AnswersToShow } from '../admin-dashboard/view-applications/view-application-page';

type InputProps = {
  applicationId: string;
  personId?: string;
  isClosed?: boolean;
};

export const ApplicationForm = ({
  applicationId,
  personId,
  isClosed,
}: InputProps) => {
  const questionLoader = loadQuestions(applicationId);
  const [loading, error, questions] = useAtomApi<Question[]>(
    questionLoader,
    questionsAtom,
  );

  const personLoader = loadPersonApplicationByPersonId(personId);
  const [
    personAppLoading,
    personAppError,
    personAppResult,
    setPersonAppResult,
  ] = useAtomApi<PersonApplicationRequest>(personLoader, personApplicationAtom);

  let personApplication: PersonApplication | null | undefined;
  if (personId && typeof personAppResult?.result !== 'undefined') {
    personApplication = personAppResult.result;
  }

  const personApplicationResponseHasHappened =
    !personId || (personId && typeof personApplication !== 'undefined');

  return (
    <DataWrapper
      loading={loading || personAppLoading}
      error={error || personAppError}
    >
      {personApplicationResponseHasHappened ? (
        <AnswerLoader
          applicationId={applicationId}
          personId={personId}
          personApplication={personApplication}
          setPersonAppResult={setPersonAppResult}
          questions={questions}
          isClosed={isClosed}
        />
      ) : null}
    </DataWrapper>
  );
};

type ComponentProps = InputProps & {
  questions: Question[];
  personApplication?: PersonApplication | null;
  setPersonAppResult: (personAppResult: PersonApplicationRequest) => void;
  isClosed?: boolean;
};

type AppProps = ComponentProps & {
  answers: Answer[];
  setAnswers: (answers: Answer[]) => void;
};

const getDefaultValues = (questions: Question[], answers: Answer[]) => {
  return (
    questions?.reduce((acc, curr) => {
      const response =
        answers?.find((a) => a.questionId === curr.questionId)?.response || '';
      if (curr.questionType === 'questLearningQuestion') {
        const checkedOptions = response.split(',');
        LearningOptions.forEach((option) => {
          const checked = checkedOptions.find((o) => option === o) || false;
          acc[getLearningName(curr.questionId, option)] = Boolean(checked);
        });
      } else if (curr.questionType === 'referenceQuestion') {
        const [name, email, phone] = response.split(',');
        acc[`${curr.questionId}-name`] = name || '';
        acc[`${curr.questionId}-email`] = email || '';
        acc[`${curr.questionId}-phone`] = phone || '';
      } else {
        acc[curr.questionId] = response;
      }

      return acc;
    }, {} as Record<string, any>) || {}
  );
};

const AnswerLoader = (props: ComponentProps) => {
  const answerLoader = loadAnswersByPersonApplicationId(
    props.personApplication?.personApplicationId,
  );
  const [answerLoading, answerError, answers, setAnswers] = useAtomApi<
    Answer[]
  >(answerLoader, answersAtom);

  return (
    <DataWrapper loading={answerLoading} error={answerError}>
      <ApplicationFormComponent
        {...props}
        answers={answers}
        setAnswers={setAnswers}
      />
    </DataWrapper>
  );
};

export const ApplicationFormComponent = ({
  applicationId,
  questions,
  personApplication,
  setPersonAppResult,
  personId,
  answers,
  setAnswers,
  isClosed,
}: AppProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (!personApplication && personId) {
      API.post<PersonApplication>('/person-application', {
        personId,
        applicationId,
      }).then((result) => {
        setPersonAppResult({
          result,
        });
      });
    }
  }, [applicationId, personApplication, personId, setPersonAppResult]);

  const defaultValues = getDefaultValues(questions, answers);
  const {
    control,
    // setValue,
    formState: { errors },
    handleSubmit,
    getValues,
    // watch,
    reset,
    // trigger,
  } = useForm({ defaultValues, mode: 'all' });

  const hasErrors = Object.keys(errors).length > 0;

  const onSave = () => {
    const values = getValues();
    onSubmit(false)(values);
  };

  const onSubmit: (isSubmit: boolean) => SubmitHandler<typeof defaultValues> =
    (isSubmit: boolean) => async (data) => {
      if (!personApplication) {
        return;
      }
      setIsLoading(true);

      let requestObj: { [key: string]: string | { [subKey: string]: string } } =
        {};
      Object.keys(data).forEach((key) => {
        let questionId = key;
        let subKey = undefined;
        if (key.includes('-')) {
          [questionId, subKey] = key.split('-');
        }
        if (subKey) {
          const curr = data[key];
          requestObj[questionId] = {
            ...(requestObj[questionId] ? (requestObj[questionId] as any) : {}),
            [subKey]: curr,
          };
        } else {
          requestObj[questionId] = data[key];
        }
      });

      const answerRequest = Object.keys(requestObj).map((questionId) => {
        let response;
        const curr = requestObj[questionId];
        if (typeof curr === 'string') {
          response = curr;
        } else {
          const question = questions.find((q) => q.questionId === questionId);
          if (!question) {
            throw new Error('unexpected question');
          }
          if (question.questionType === 'referenceQuestion') {
            response = `${curr['name'] ?? ''},${curr['email'] ?? ''},${
              curr['phone'] ?? ''
            }`;
          } else if (question.questionType === 'questLearningQuestion') {
            response = Object.keys(curr)
              .filter((ans) => Boolean(curr[ans]))
              .join(',');
          }
        }

        const answerId = answers?.find(
          (a) => a.questionId === questionId,
        )?.answerId;

        return {
          answerId,
          questionId,
          personApplicationId: personApplication.personApplicationId,
          response,
        };
      });

      const response = await API.post<Answer[]>(
        '/answers/bulk-update',
        answerRequest,
      );

      setIsLoading(false);

      if (isSubmit) {
        const newPersonApplication = {
          ...personApplication,
          submittedAt: new Date().toISOString(),
        };
        await API.post<PersonApplication>(
          `/person-application/${personApplication.personApplicationId}`,
          newPersonApplication,
        );
        setPersonAppResult({
          result: newPersonApplication,
        });
        setSnackbarMessage('Application submitted successfully');
      } else {
        setSnackbarMessage('Application saved successfully');
      }

      setAnswers(response);
      reset(getDefaultValues(questions, response));
    };

  const unsubmitApplication = async () => {
    if (personApplication) {
      const newPersonApplication = {
        ...personApplication,
        submittedAt: null,
      };
      await API.post<PersonApplication>(
        `/person-application/${personApplication.personApplicationId}`,
        newPersonApplication,
      );
      setPersonAppResult({
        result: newPersonApplication,
      });
    }
  };

  const QUESTION_COMPONENTS: any = {
    shortText: ShortTextQuestion,
    text: TextQuestion,
    cohortPreference: CohortQuestion,
    questLearningQuestion: QuestLearningQuestion,
    referenceQuestion: ReferenceQuestion,
  };

  const sortedQuestions =
    questions?.sort((a, b) => a.sortOrder - b.sortOrder) || [];

  const showUnsubmit = Boolean(!isClosed && personApplication?.submittedAt);
  const showViewOnly = Boolean(isClosed || personApplication?.submittedAt);

  const closeSnackbar = () => setSnackbarMessage(null);

  return (
    <div className="u-maxWidth-700px">
      <Snackbar
        open={Boolean(snackbarMessage)}
        autoHideDuration={5000}
        onClose={closeSnackbar}
        message={snackbarMessage}
      />
      {showUnsubmit ? (
        <Alert
          severity="info"
          action={
            <Button size="small" onClick={unsubmitApplication}>
              Edit Application
            </Button>
          }
        >
          Thank you for submitting your application.
        </Alert>
      ) : null}
      {isClosed ? (
        <Alert severity="warning">
          The QUEST Application is currently closed for this year. Below you
          will find your application. Good luck!
        </Alert>
      ) : null}
      {showViewOnly ? (
        <AnswersToShow questions={sortedQuestions} answers={answers} />
      ) : (
        sortedQuestions?.map((q, index) => {
          const Component = QUESTION_COMPONENTS[q.questionType];
          const questionNumber = index + 1;
          return Component ? (
            <Component
              key={q.questionId}
              control={control}
              name={q.questionId}
              label={q.questionHTML}
              index={questionNumber}
              required
            />
          ) : null;
        })
      )}
      {hasErrors ? (
        <div className="u-color-red">
          You must fill in every question before submitting. They must be the
          correct length.
        </div>
      ) : null}
      {!showViewOnly ? (
        <>
          <Button variant="contained" disabled={isLoading} onClick={onSave}>
            Save
          </Button>
          <Button
            variant="contained"
            disabled={
              isLoading || hasErrors || Boolean(personApplication?.submittedAt)
            }
            onClick={handleSubmit(onSubmit(true))}
            className="u-margin-left"
          >
            Submit
          </Button>
        </>
      ) : null}
    </div>
  );
};
