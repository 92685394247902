import { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  applicationsAtom,
  loadApplications,
  Application,
} from './manage-applications.atoms';
import { NavigationController } from '../../bootstrap/history-spy';
import { DataWrapper } from '../../utilities/data-wrapper';
import { useAtomApi } from '../../utilities/use-fetch';
import { DialogWrapper } from '../../utilities/dialog-wrapper';
import { DeleteButton } from '../../utilities/delete-button';
import { FormTextField } from '../../utilities/react-hook-form-connectors/form-text-field';
import { FormDatePicker } from '../../utilities/react-hook-form-connectors/form-date-picker';
import { API } from '../../utilities/api';

export const ManageApplicationsPage = () => {
  const [loading, error, apps, setApplications] = useAtomApi<Application[]>(
    loadApplications,
    applicationsAtom,
  );
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);

  const dataGridRows = (apps || []).map((app) => ({
    ...app,
    id: app.applicationId,
  }));

  const handleToggleCreateDialog = () => {
    setCreateDialogOpen(!createDialogOpen);
  };

  const onApplicationDelete = (applicationId: string) => {
    setApplications(
      apps.filter((a) => a.applicationId !== applicationId).slice(),
    );
  };

  const columns: GridColDef<Application>[] = [
    { field: 'id', headerName: 'Application ID', flex: 1 },
    { field: 'applicationName', headerName: 'Name', flex: 1 },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      valueGetter: (params) => dayjs(params.row.dueDate).format('MM/DD/YYYY'),
      flex: 1,
    },
    {
      field: 'cohorts',
      headerName: 'Cohorts',
      valueGetter: (params) => params.row.cohorts.join(', '),
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      flex: 1,
      renderHeader: () => (
        <Button onClick={handleToggleCreateDialog}>Add</Button>
      ),
      renderCell: (params) => (
        <>
          <Button
            onClick={() =>
              NavigationController.redirectTo(
                `/admin-dashboard/manage-application/${params.row.applicationId}`,
              )
            }
          >
            Edit
          </Button>
          <DeleteButton
            id={params.row.applicationId}
            path="/applications/"
            name="application"
            onDelete={onApplicationDelete}
          />
        </>
      ),
    },
  ];

  const defaultValues = {
    applicationName: '',
    dueDate: '',
    lowCohort: '',
    highCohort: '',
  };

  const {
    control,
    // setValue,
    formState: { isDirty, errors },
    handleSubmit,
    // watch,
    reset,
    // trigger,
  } = useForm({ defaultValues });

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    const newApp = await API.post<Application>(`/applications/`, {
      applicationName: data.applicationName,
      dueDate: data.dueDate,
      cohorts: [data.lowCohort, data.highCohort],
    });
    reset(defaultValues);
    apps.push(newApp);
    setApplications(apps.slice());
    setCreateDialogOpen(false);
  };

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <>
      <DataWrapper loading={loading} error={error}>
        <DataGrid rows={dataGridRows} columns={columns} />
      </DataWrapper>

      <DialogWrapper
        onClose={handleToggleCreateDialog}
        title="Create New Application"
        open={createDialogOpen}
      >
        <DialogWrapper.Content>
          <div className="u-margin-top">
            <FormTextField
              control={control}
              name="applicationName"
              label="Application Name"
              required
            />
            <FormDatePicker
              control={control}
              name="dueDate"
              label="Due Date"
              className="u-margin-top"
            />

            <div className="u-spread u-margin-top">
              <FormTextField
                control={control}
                name="lowCohort"
                label="Lower Cohort Bound"
                className="u-margin-right"
                required
              />
              -
              <FormTextField
                control={control}
                name="highCohort"
                label="Upper Cohort Bound"
                className="u-margin-left"
                required
              />
            </div>
          </div>
        </DialogWrapper.Content>
        <DialogWrapper.Actions>
          <Button variant="contained" onClick={handleToggleCreateDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={hasErrors || !isDirty}
          >
            Save
          </Button>
        </DialogWrapper.Actions>
      </DialogWrapper>
    </>
  );
};
