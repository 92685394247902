import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import Button from '@mui/material/Button';

import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import './landing-page.scss';

import questLogoBanner from './images/questLogoBanner.png';

import { landingPageData } from './landing-page.atoms';
import { CasUtility } from '../utilities/cas-utility';

export const LandingPage = () => {
  const [publicData] = useAtom(landingPageData);
  if (!publicData) {
    return null;
  }
  const { currentApplication } = publicData;
  const isClosed = dayjs(currentApplication.dueDate)
    .add(7, 'hours')
    .isBefore(dayjs());

  const handleCasLogin = () => {
    CasUtility.login();
  };
  return (
    <>
      {/* <!-- Header --> */}
      <div id="header">
        <img
          src={questLogoBanner}
          width="50%"
          height="auto"
          alt="The logo of the QUEST program at UMD"
        />
        <br />
        {!isClosed ? (
          <div className="12u">
            <ul className="actions">
              <Button variant="contained" size="large" onClick={handleCasLogin}>
                Start Your Application
              </Button>
            </ul>
          </div>
        ) : (
          <div>
            Applications are closed for{' '}
            {dayjs(currentApplication.dueDate).year()}. Good luck to all who
            applied!
          </div>
        )}
      </div>

      {/* <!-- Main --> */}
      <div id="main">
        <header className="major container 75%">
          <h2>QUEST</h2>

          <p>
            is composed of students from
            <br />
            <span style={{ color: 'rgb(131,23,27)' }}>
              The Robert H. Smith School of Business
            </span>
            ,
            <br />
            <span style={{ color: 'rgb(0,0,0)' }}>
              The A. James Clark School of Engineering
            </span>
            ,
            <br />
            and
            <br />
            <span style={{ color: 'rgb(131,23,27)' }}>
              The College of Computer, Mathematical, and Natural Sciences
            </span>
            .
          </p>
          <br />
          <p>
            is a challenging, hands-on learning community for motivated
            students.
          </p>
          <br />
          <p>
            is a three-year, five-course program in which students complete
            team-based, experiential learning projects.
          </p>
        </header>

        <div className="box container">
          <header>
            <h2>Hear From QUEST students!</h2>
            <p>We recorded testimonials about QUEST, done by QUEST students.</p>
          </header>
          <section>
            <div className="video-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/r6p2Io6PHWc"
                title="QUEST Honors Program at the University of Maryland"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </section>
        </div>

        <div className="box container">
          {!isClosed ? (
            <>
              <header>
                <h2>Get Started On Your Application Today!</h2>
                <div className="12u">
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleCasLogin}
                  >
                    Start Your Application
                  </Button>
                </div>
              </header>
              <header>
                <h3>
                  <br />
                  <span style={{ color: 'rgb(131,23,27)' }}>
                    Due by{' '}
                    {dayjs(currentApplication.dueDate).format(
                      'dddd, MMM D, YYYY',
                    )}{' '}
                    at 11:59 pm for Cohorts {currentApplication.cohorts[0]} and{' '}
                    {currentApplication.cohorts[1]}
                  </span>
                </h3>
              </header>
            </>
          ) : (
            <p>
              Applications are closed for{' '}
              {dayjs(currentApplication.dueDate).year()}. Good luck to all who
              applied!
            </p>
          )}
          <p>
            Applications will be reviewed by a selection committee composed of
            students, alumni, faculty, and staff. Upon review of the
            applications, selected students will be invited for an interview in
            March. Applicants will be notified of their final status in March,
            prior to spring registration.
          </p>
        </div>
      </div>

      {/* <!-- Footer --> */}
      <div id="footer">
        <div className="container 75%">
          <header className="major last">
            <h2>Connect with us!</h2>
          </header>

          <p>
            More information about the QUEST program can be found{' '}
            <a href="https://www.rhsmith.umd.edu/quest">here</a>. Please direct
            any questions to QUEST at{' '}
            <a href="mailto:questhonors@umd.edu">questhonors@umd.edu</a>.
          </p>

          <ul className="icons">
            <li>
              <a href="http://www.twitter.com/QUESTumd">
                <TwitterIcon style={{ paddingTop: '5px' }} />
              </a>
            </li>
            <li>
              <a href="http://www.facebook.com/QUESTumd">
                <FacebookIcon style={{ paddingTop: '5px' }} />
              </a>
            </li>
            <li>
              <a href="http://www.instagram.com/QUESTumd">
                <InstagramIcon style={{ paddingTop: '5px' }} />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/quest-honors-program-at-umd">
                <LinkedInIcon style={{ paddingTop: '5px' }} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
