import { API_URL } from '../config/config';

const casURL = 'https://login.umd.edu/cas/login';

export class CasUtility {
  static login = () => {
    const query = {
      service: `${API_URL}/auth/cas/`, 
    };
    const url = `${casURL}?${new URLSearchParams(query).toString()}`;
    window.location.replace(url);
  };
}
