import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import { DataWrapper } from '../../utilities/data-wrapper';
import { useAtomApi } from '../../utilities/use-fetch';
import { ApplicationForm } from '../../application/application-form';
import {
  Application,
  loadApplications,
  applicationsAtom,
} from '../manage-applications/manage-applications.atoms';

export const PreviewApplicationPage = () => {
  const [loading, error, apps] = useAtomApi<Application[]>(
    loadApplications,
    applicationsAtom,
  );
  const [selectedApplicationId, setSelectedApplicationId] =
    useState<string>('');

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedApplicationId(event.target.value as string);
  };

  return (
    <DataWrapper loading={loading} error={error}>
      <div className="u-padding">
        <div className="u-width-100">
          <FormControl fullWidth>
            <InputLabel>Application</InputLabel>
            <Select
              value={selectedApplicationId}
              onChange={handleChange}
              label="Application"
              autoWidth
              className="u-minWidth-300px"
            >
              {(apps || []).map((app) => (
                <MenuItem key={app.applicationId} value={app.applicationId}>
                  {app.applicationName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          {selectedApplicationId ? (
            <ApplicationForm applicationId={selectedApplicationId} />
          ) : null}
        </div>
      </div>
    </DataWrapper>
  );
};
