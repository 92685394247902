
export class LocalStorageController {
  static browserSupportsLocalStorage = () => {
    const keyValue = 'teamEngine';
    try {
      window.localStorage.setItem(keyValue, keyValue);
      window.localStorage.removeItem(keyValue);
      return true;
    } catch (e) {
      return false;
    }
  };

  static setItem = (key: string, value: string) => {
    if (!LocalStorageController.browserSupportsLocalStorage()) {
      return;
    }
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      // This is basically always going to be a QuotaExceededError, which we can't
      // really control. It's also possible that the user disabled local storage.
      // In either case, we just want to move on
    }
  };

  static removeItem = (key: string) => {
    if (!LocalStorageController.browserSupportsLocalStorage()) {
      return;
    }
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      // Unclear if this can fail, but don't want the UI to bomb out if it does
    }
  };

  static getItem = (key: string) => {
    if (!LocalStorageController.browserSupportsLocalStorage()) {
      return null;
    }
    try {
      return window.localStorage.getItem(key);
    } catch (e) {
      // Unclear if this can fail, but don't want the UI to bomb out if it does
    }
    return null;
  };
}
