import { atom } from 'jotai';
import { API } from '../utilities/api';

export type Application = {
  applicationId: string;
  applicationName: string;
  dueDate: Date;
  cohorts: Array<string>;
};

export type PersonApplication = {
  personApplicationId: string;
  applicationId: string;
  personId: string;
  submittedAt?: string | null;
};

export type PersonApplicationRequest = {
  result: PersonApplication | null;
};

export type Answer = {
  answerId: string;
  questionId: string;
  response: string;
};

export const loadCurrentApplication = async () => {
  return API.get<Application>('/applications/active');
};
export const currentApplicationAtom = atom<AtomLoadedData<Application>>(null);

export const loadPersonApplicationByPersonId =
  (personId?: string) => async () => {
    if (!personId) return { result: null };
    return API.get<PersonApplicationRequest>(
      `/person-application?personId=${personId}`,
    );
  };
export const personApplicationAtom =
  atom<AtomLoadedData<PersonApplicationRequest>>(null);

export const loadAnswersByPersonApplicationId =
  (personApplicationId?: string) => async () => {
    if (!personApplicationId) return null;
    return API.get<Answer[]>(
      `/answers?personApplicationId=${personApplicationId}`,
    );
  };
export const answersAtom = atom<AtomLoadedData<Answer[]>>(null);
