export const SCHOOLS = {
  business: 'Robert H. Smith School of Business',
  cmns: 'College of Computer, Mathematical, and Natural Sciences',
  engineering: 'A. James Clark School of Engineering',
};

export const MAJORS = [
  { major: 'Accounting', school: SCHOOLS.business },
  { major: 'Finance', school: SCHOOLS.business },
  { major: 'Management', school: SCHOOLS.business },
  { major: 'Information Systems', school: SCHOOLS.business },
  { major: 'International Business', school: SCHOOLS.business },
  { major: 'Supply Chain Management', school: SCHOOLS.business },
  { major: 'Marketing', school: SCHOOLS.business },
  {
    major: 'Operations Management and Business Analytics',
    school: SCHOOLS.business,
  },
  { major: 'Astronomy', school: SCHOOLS.cmns },
  { major: 'Atmospheric and Oceanic Science', school: SCHOOLS.cmns },
  { major: 'Biochemistry', school: SCHOOLS.cmns },
  { major: 'Biological Sciences', school: SCHOOLS.cmns },
  { major: 'Chemistry', school: SCHOOLS.cmns },
  { major: 'Computer Science', school: SCHOOLS.cmns },
  {
    major: 'Environmental Sciences - Biodiversity and Conservation',
    school: SCHOOLS.cmns,
  },
  {
    major: 'Environmental Sciences - Environmental Geosciences and Restoration',
    school: SCHOOLS.cmns,
  },
  { major: 'Geology', school: SCHOOLS.cmns },
  { major: 'Mathematics', school: SCHOOLS.cmns },
  { major: 'Physics', school: SCHOOLS.cmns },
  { major: 'Aerospace Engineering', school: SCHOOLS.engineering },
  { major: 'Bioengineering', school: SCHOOLS.engineering },
  { major: 'Chemical & Biomolecular Engineering', school: SCHOOLS.engineering },
  { major: 'Civil & Environmental Engineering', school: SCHOOLS.engineering },
  { major: 'Computer Engineering', school: SCHOOLS.engineering },
  { major: 'Electrical Engineering', school: SCHOOLS.engineering },
  { major: 'Fire Protection Engineering', school: SCHOOLS.engineering },
  { major: 'Materials Science & Engineering', school: SCHOOLS.engineering },
  { major: 'Mechanical Engineering', school: SCHOOLS.engineering },
  { major: 'Business Undecided', school: SCHOOLS.business },
  { major: 'Clark Undecided', school: SCHOOLS.engineering },
  { major: 'CMNS Undecided', school: SCHOOLS.cmns },
  { major: 'Neuroscience ', school: SCHOOLS.cmns },
  { major: 'Immersive Media Design ', school: SCHOOLS.cmns },
];

export const MAJOR_MAP: { [major: string]: { major: string; school: string } } =
  MAJORS.reduce((prev, curr) => {
    return {
      ...prev,
      [curr.major]: curr,
    };
  }, {});

export const BUSINESS_MAJORS = MAJORS.filter(
  (m) => m.school === SCHOOLS.business,
).sort((a, b) => a.major.localeCompare(b.major));
export const CMNS_MAJORS = MAJORS.filter((m) => m.school === SCHOOLS.cmns).sort(
  (a, b) => a.major.localeCompare(b.major),
);
export const ENGINEERING_MAJORS = MAJORS.filter(
  (m) => m.school === SCHOOLS.engineering,
).sort((a, b) => a.major.localeCompare(b.major));

export const MINORS = [
  { minor: 'Business Analytics', school: SCHOOLS.business },
  { minor: 'Astronomy', school: SCHOOLS.cmns },
  { minor: 'Meteorology', school: SCHOOLS.cmns },
  { minor: 'Atmospheric Science', school: SCHOOLS.cmns },
  { minor: 'Chemistry', school: SCHOOLS.cmns },
  { minor: 'Computer Science', school: SCHOOLS.cmns },
  { minor: 'Earth History', school: SCHOOLS.cmns },
  { minor: 'Earth Material Properties', school: SCHOOLS.cmns },
  { minor: 'Geophysics', school: SCHOOLS.cmns },
  { minor: 'Hydrology', school: SCHOOLS.cmns },
  { minor: 'Actuarial Mathematics', school: SCHOOLS.cmns },
  { minor: 'Mathematics', school: SCHOOLS.cmns },
  { minor: 'Statistics', school: SCHOOLS.cmns },
  { minor: 'Physics', school: SCHOOLS.cmns },
];

export const HONORS_PROGRAMS = [
  { program: 'ACES' },
  { program: 'CIVICUS' },
  { program: 'College Park Scholars - Arts' },
  { program: 'College Park Scholars - Business, Society and the Economy' },
  { program: 'College Park Scholars - Environment, Technology and Economy' },
  { program: 'College Park Scholars - Global Public Health' },
  { program: 'College Park Scholars - International Studies' },
  { program: 'College Park Scholars - Justice and Legal Thought' },
  { program: 'College Park Scholars - Life Sciences' },
  { program: 'College Park Scholars - Media, Self and Society' },
  { program: 'College Park Scholars - Other' },
  { program: 'College Park Scholars - Public Leadership' },
  { program: 'College Park Scholars - Science and Global Change' },
  { program: 'College Park Scholars - Science, Discovery and the Universe' },
  { program: 'College Park Scholars - Science, Technology and Society' },
  { program: 'Design Cultures & Creativity' },
  { program: 'FIRE' },
  { program: 'Flexus' },
  { program: 'Gemstone' },
  { program: 'Honors Global Communities' },
  { program: 'Honors Humanities' },
  { program: 'Integrated Life Sciences' },
  { program: 'Interdisciplinary Business Honors' },
  { program: 'Smith Business Leadership Fellows (SBLF)' },
  { program: 'University Honors' },
  { program: 'Virtus' },
  { program: 'Other' },
];
