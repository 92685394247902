import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

type TextFieldInput<T extends FieldValues> = UseControllerProps<T> & {
  label?: string;
  required?: boolean;
  className?: string;
};

export const FormRichTextField = <T extends FieldValues>({
  control,
  name,
  rules,
  label,
  className,
  required,
}: TextFieldInput<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        ...rules,
        required: required || rules?.required ? 'Required' : false,
      }}
      render={({ field, fieldState: { error } }) => {
        const onChangeHandler = (_event: Event, editor: ClassicEditor) => {
          field.onChange(editor.getData());
        };
        const hasError = Boolean(error);
        return (
          <>
            <InputLabel
              error={Boolean(error)}
              required={required}
              size="small"
              className="u-margin-bottom-xl"
            >
              {label}
            </InputLabel>
            <div
              className={`${className || ''} ${hasError ? 'u-red-border' : ''}`}
            >
              <CKEditor
                {...field}
                editor={ClassicEditor}
                data={field.value}
                onChange={onChangeHandler}
              />
            </div>
            <FormHelperText className="u-color-red">
              {error?.message}
            </FormHelperText>
          </>
        );
      }}
    />
  );
};
