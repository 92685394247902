import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';

import { DialogWrapper } from './dialog-wrapper';
import { theme } from '../bootstrap/theme';
import Button from '@mui/material/Button';

type InputProps = {
  question: JSX.Element | string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  title: string;
  onClose: () => void;
};

let resolve: Function;

const ConfirmationDialog = ({
  question,
  cancelButtonLabel,
  confirmButtonLabel,
  title,
  onClose,
}: InputProps) => {
  const handleCancel = () => {
    resolve(false);
    onClose();
  };

  const handleConfirm = () => {
    resolve(true);
    onClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <DialogWrapper onClose={handleCancel} size="xs" title={title}>
        <DialogWrapper.Content>
          <div className="typo-title">{question}</div>
        </DialogWrapper.Content>
        <DialogWrapper.Actions>
          <>
            <Button onClick={handleCancel}>{cancelButtonLabel}</Button>
            <Button onClick={handleConfirm}>{confirmButtonLabel}</Button>
          </>
        </DialogWrapper.Actions>
      </DialogWrapper>
    </ThemeProvider>
  );
};

export const collectConfirmation = (
  question: JSX.Element | string,
  options?: {
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    title?: string;
  },
) => {
  const { cancelButtonLabel, confirmButtonLabel, title } = {
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: 'Yes',
    title: 'Are you sure?',
    ...options,
  };
  const containerElement = document.createElement('div');
  document.body.appendChild(containerElement);
  const root = createRoot(containerElement);

  const onClose = () => {
    root.unmount();
    containerElement.remove();
  };

  root.render(
    <ConfirmationDialog
      question={question}
      cancelButtonLabel={cancelButtonLabel}
      confirmButtonLabel={confirmButtonLabel}
      title={title}
      onClose={onClose}
    />,
  );
  return new Promise((res) => {
    resolve = res;
  });
};
