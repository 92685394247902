import { Button, ListSubheader, MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { API } from '../utilities/api';
import { DataWrapper } from '../utilities/data-wrapper';
import { FormCheckbox } from '../utilities/react-hook-form-connectors/form-checkbox';
import { FormSelect } from '../utilities/react-hook-form-connectors/form-select';
import { FormTextField } from '../utilities/react-hook-form-connectors/form-text-field';
import {
  BUSINESS_MAJORS,
  CMNS_MAJORS,
  ENGINEERING_MAJORS,
  HONORS_PROGRAMS,
  SCHOOLS,
} from '../utilities/umd-information';
import { useAtomApi } from '../utilities/use-fetch';
import {
  Application,
  currentApplicationAtom,
  loadCurrentApplication,
} from './application.atoms';
import { loadPerson, Person, personAtom } from './person.atoms';

type InputProps = {
  personId: string;
};

// This seems to be a bug on Xata's side - according to this: https://xata.io/docs/rest-api/limits
// and the error, we should get 2048 chars here, but testing empirically we're getting limited to just
// 255
const MAX_EXTENUATING_CIRCUMSTANCE_LENGTH = 255;

export const PersonForm = ({ personId }: InputProps) => {
  const [
    currentApplicationLoading,
    currentApplicationError,
    currentApplication,
  ] = useAtomApi<Application>(loadCurrentApplication, currentApplicationAtom);

  const [loading, error, person, setPerson] = useAtomApi<Person>(
    loadPerson(personId),
    personAtom,
  );

  const onPersonUpdate = (personUpdate: Person) => {
    setPerson(personUpdate);
  };

  return (
    <DataWrapper
      loading={loading || currentApplicationLoading}
      error={error || currentApplicationError}
    >
      {currentApplication && person ? (
        <PersonFormComponent
          person={person}
          onPersonUpdate={onPersonUpdate}
          currentApplication={currentApplication}
        />
      ) : null}
    </DataWrapper>
  );
};

type ComponentProps = {
  person: Person;
  onPersonUpdate: (personUpdate: Person) => void;
  currentApplication: Application;
};

export const PersonFormComponent = ({
  person,
  onPersonUpdate,
  currentApplication,
}: ComponentProps) => {
  const defaultValues = {
    ...person,
    questRequirementDoesNotMeetAndHasExplained: false,
  };

  const {
    control,
    // setValue,
    formState: { isDirty, errors },
    handleSubmit,
    // watch,
    reset,
    // trigger,
  } = useForm({ defaultValues, mode: 'all' });

  const navigate = useNavigate();

  const onSubmitGenerator: (
    navigateToApp: boolean,
  ) => SubmitHandler<typeof defaultValues> =
    (navigateToApp: boolean) => async (data) => {
      const updatedPerson = await API.post<Person>(
        `/people/${person.personId}`,
        {
          ...data,
        },
      );
      reset(updatedPerson);
      onPersonUpdate(updatedPerson);
      if (navigateToApp) {
        navigate('/applicant-dashboard/application');
      }
    };

  const hasErrors = Object.keys(errors).length > 0;

  const secondYear = dayjs(currentApplication.dueDate)
    .add(2, 'year')
    .format('YYYY');
  const thirdYear = dayjs(currentApplication.dueDate)
    .add(3, 'year')
    .format('YYYY');
  const fourthYear = dayjs(currentApplication.dueDate)
    .add(4, 'year')
    .format('YYYY');

  return (
    <div className="u-maxWidth-700px">
      <h1>Profile Information</h1>
      <FormTextField
        control={control}
        name="firstName"
        label="First Name"
        required
      />
      <FormTextField
        control={control}
        name="preferredName"
        label="Preferred Name"
        className="u-margin-top"
      />
      <FormTextField
        control={control}
        name="lastName"
        label="Last Name"
        className="u-margin-top"
        required
      />
      <FormTextField
        control={control}
        name="email"
        label="Email"
        className="u-margin-top"
        required
        rules={{
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Incorrect email format',
          },
        }}
      />
      <FormTextField
        control={control}
        name="phoneNumber"
        label="Phone Number"
        className="u-margin-top"
      />
      <FormSelect
        control={control}
        name="gender"
        label="Gender"
        className="u-margin-top"
        required
      >
        <MenuItem value="m">Male</MenuItem>
        <MenuItem value="f">Female</MenuItem>
        <MenuItem value="n">Non-Binary</MenuItem>
        <MenuItem value="o">Prefer not to answer</MenuItem>
      </FormSelect>
      <FormSelect
        control={control}
        name="pronouns"
        label="Preferred Pronouns"
        className="u-margin-top"
        required
      >
        <MenuItem value="h">He/Him</MenuItem>
        <MenuItem value="s">She/Her</MenuItem>
        <MenuItem value="t">They/Them</MenuItem>
        <MenuItem value="z">Ze/Zir</MenuItem>
        <MenuItem value="n">A pronoun not listed</MenuItem>
        <MenuItem value="o">No pronoun preference</MenuItem>
        <MenuItem value="p">Prefer not to answer</MenuItem>
      </FormSelect>
      <h1>Educational Information</h1>
      <span>
        Please verify that you meet the following requirements for admission
        into QUEST:
      </span>
      <div className="u-margin-left">
        <div>
          <FormCheckbox
            name="questRequirementCompletedSemester"
            control={control}
            label="I've completed a semester in college"
          />
        </div>
        <div>
          <FormCheckbox
            name="questRequirementCurrentFreshman"
            control={control}
            label={`I am currently a freshman and/or plan to graduate in Fall ${secondYear} or later`}
          />
        </div>
        <div>
          <FormCheckbox
            name="questRequirementGpa"
            control={control}
            label="I currently have a GPA of 3.0 or above"
          />
        </div>
        <div>
          <FormCheckbox
            name="questRequirementCurrentlyEnrolled"
            control={control}
            label="I am currently enrolled in a major(s) offered by the Robert H. Smith School of Business, the A. James Clark School of Engineering, and/or the College of Computer, Mathematical, and Natural Sciences"
          />
        </div>
        <span>Or, if you do not meet these criteria:</span>
        <div>
          <FormCheckbox
            name="questRequirementDoesNotMeetAndHasExplained"
            control={control}
            label="I do not meet all of the admission criteria. I have explained my situation below in the Extenuating Circumstances field"
          />
        </div>
      </div>
      <span className="u-margin-top">
        If checked the box that you do not meet the criteria, or you have other
        extenuating circumstances, please explain in the notes section below (in{' '}
        {MAX_EXTENUATING_CIRCUMSTANCE_LENGTH} characters or less):
      </span>
      <FormTextField
        name="questRequirementExtenuatingCircumstances"
        label="Extenuating Circumstances"
        control={control}
        multiline
        className="u-margin-top"
        rules={{
          validate: {
            lessThanMaxStringLength: (value): string | undefined => {
              if (String(value).length > MAX_EXTENUATING_CIRCUMSTANCE_LENGTH) {
                return `Please use ${MAX_EXTENUATING_CIRCUMSTANCE_LENGTH} characters or less. Current character count: ${
                  String(value).length
                }`;
              }
            },
          },
        }}
      />
      <FormTextField
        name="gpa"
        control={control}
        label="gpa"
        className="u-margin-top"
        required
      />
      <FormTextField
        name="creditsCompletedAtUMD"
        control={control}
        label="College Credits Completed"
        className="u-margin-top"
        required
      />
      <FormSelect
        control={control}
        name="firstGenerationCollegeStudent"
        label="Do you identify as a first-generation college student?"
        className="u-margin-top"
        required
      >
        <MenuItem value="n">No</MenuItem>
        <MenuItem value="y">Yes</MenuItem>
        <MenuItem value="u">Unsure</MenuItem>
        <MenuItem value="p">Prefer not to answer</MenuItem>
      </FormSelect>
      <FormSelect
        control={control}
        name="plannedGraduatingYear"
        label="Which semester do you plan to graduate?"
        className="u-margin-top"
        required
      >
        <MenuItem value={`Fall ${secondYear}`}>Fall {secondYear}</MenuItem>
        <MenuItem value={`Spring ${thirdYear}`}>Spring {thirdYear}</MenuItem>
        <MenuItem value={`Fall ${thirdYear}`}>Fall {thirdYear}</MenuItem>
        <MenuItem value={`Spring ${fourthYear}`}>Spring {fourthYear}</MenuItem>
        <MenuItem value={`Fall ${fourthYear}`}>Fall {fourthYear}</MenuItem>
        <MenuItem value={`Other`}>Other</MenuItem>
      </FormSelect>
      <FormSelect
        control={control}
        name="major"
        label="Current Major(s)"
        className="u-margin-top"
        multiple
        required
      >
        <ListSubheader>{SCHOOLS.business}</ListSubheader>
        {BUSINESS_MAJORS.map((m) => (
          <MenuItem key={m.major} value={m.major}>
            {m.major}
          </MenuItem>
        ))}
        <ListSubheader>{SCHOOLS.cmns}</ListSubheader>
        {CMNS_MAJORS.map((m) => (
          <MenuItem key={m.major} value={m.major}>
            {m.major}
          </MenuItem>
        ))}
        <ListSubheader>{SCHOOLS.engineering}</ListSubheader>
        {ENGINEERING_MAJORS.map((m) => (
          <MenuItem key={m.major} value={m.major}>
            {m.major}
          </MenuItem>
        ))}
        <ListSubheader>Other Majors</ListSubheader>
        <MenuItem value="Letters and Sciences">
          Letters and Sciences (will be enrolled in one of the 3 partner
          colleges before starting QUEST)
        </MenuItem>
        <MenuItem value="other">Other</MenuItem>
      </FormSelect>
      <FormSelect
        control={control}
        name="honorsProgram"
        label="Honors Programs"
        className="u-margin-top"
        multiple
      >
        {HONORS_PROGRAMS.map((hp) => (
          <MenuItem key={hp.program} value={hp.program}>
            {hp.program}
          </MenuItem>
        ))}
      </FormSelect>
      <FormCheckbox
        name="freshmanConnection"
        control={control}
        label="Freshman Connection"
      />

      <div className="u-spread">
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmitGenerator(true))}
          disabled={hasErrors || !isDirty}
        >
          Save and Start Application
        </Button>
      </div>
    </div>
  );
};
