import { atom } from "jotai"
import { LocalStorageController } from './local-storage-controller';

export const atomWithLocalStorage = <T>(key: string, initialValue: T) => {
    const getInitialValue = () => {
      const item = LocalStorageController.getItem(key);
      if (item !== null) {
        return JSON.parse(item);
      }
      return initialValue;
    }
    const baseAtom = atom<T>(getInitialValue());
    const derivedAtom = atom<T, T>(
      (get) => get(baseAtom),
      (get, set, update: T) => {
        const nextValue =
          typeof update === 'function' ? update(get(baseAtom)) : update;
        set(baseAtom as any, nextValue);
        LocalStorageController.setItem(key, JSON.stringify(nextValue));
      }
    );
    return derivedAtom;
  }