/* eslint-disable @typescript-eslint/no-unused-vars */
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useAtom } from 'jotai';

import UMDLogo from './umd-logo.svg';
import { loginAtom } from '../login-page/login-page.atoms';
import { NavigationController } from '../bootstrap/history-spy';
import { personAtom } from '../application/person.atoms';
import { adminPersonApplicationAtom } from '../admin-dashboard/view-applications/view-applications.atoms';
import {
  personApplicationAtom,
  answersAtom,
} from '../application/application.atoms';
import {
  readerAssignmentsAtom,
  readerCriterionForApplicationAtom,
  readerScoresAtom,
} from '../reader-dashboard/scoring-page/reader-criteria.atoms';

type Props = {
  children: any;
};

export const AppFraming = ({ children }: Props) => {
  const [loginData, setLoginData] = useAtom(loginAtom);

  const [_personData, setPersonData] = useAtom(personAtom);
  const [_personApplicationData, setPersonApplicationData] = useAtom(
    personApplicationAtom,
  );
  const [_answersData, setAnswersData] = useAtom(answersAtom);
  const [_adminPersonApplication, setAdminPersonApplicationData] = useAtom(
    adminPersonApplicationAtom,
  );

  const [_readerCriterion, setReaderCriterion] = useAtom(
    readerCriterionForApplicationAtom,
  );
  const [_readerAssignments, setReaderAssignments] = useAtom(
    readerAssignmentsAtom,
  );
  const [_readerScores, setReaderScores] = useAtom(readerScoresAtom);

  const handleLogout = () => {
    setLoginData(null);
    setPersonData(null);
    setPersonApplicationData(null);
    setAnswersData(null);
    setAdminPersonApplicationData(null);
    setReaderCriterion(null);
    setReaderAssignments(null);
    setReaderScores(null);
    NavigationController.redirectTo('/login');
  };

  return (
    <>
      <AppBar position="relative">
        <div className="u-width-100 u-padding">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <div className="u-display-flex u-justifyContent-spaceEvenly u-alignItems-center">
                <img
                  src={UMDLogo}
                  alt="The University Of Maryland Logo"
                  width="300px"
                  style={{ paddingRight: '20px' }}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              {loginData ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleLogout}
                  className="u-float-right u-margin-right"
                >
                  Log out
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </AppBar>
      {children}
    </>
  );
};
