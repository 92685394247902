import { useNavigate, NavigateFunction, Outlet } from 'react-router-dom';

export class NavigationController {
  private static navigate: NavigateFunction | null;

  static setNavigate = (navigate: NavigateFunction) =>
    (NavigationController.navigate = navigate);

  static redirectTo = (url: string) => {
    if (NavigationController.navigate) {
      NavigationController.navigate(url);
    }
  };
}

export const HistorySpy = () => {
  const navigate = useNavigate();
  NavigationController.setNavigate(navigate);
  return (
    <>
      <Outlet />
    </>
  );
};
