import { API } from '../utilities/api';
import { atomWithLocalStorage } from '../utilities/atom-with-local-storage';

type AccessToken = {
    access_token: string;
}
type UserProfile = {
    userId: string,
    username: string,
    role: string | null,
    personId: string | null,
}
export type LoginResponse = {
    accessToken: AccessToken,
    user: UserProfile,
}

export const loginRequest = async (username: string, password: string) => {
    return API.post<LoginResponse>('/auth/login', {
        username,
        password,
    });
};
export const loginAtom = atomWithLocalStorage<AtomLoadedData<LoginResponse>>('loginData', null);
