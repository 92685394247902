import dayjs from 'dayjs';
import { NavigationController } from '../bootstrap/history-spy';
import { API_URL } from '../config/config';
import { LocalStorageController } from './local-storage-controller';

const getAuthToken = (): string | null => {
  const loginData = LocalStorageController.getItem('loginData');
  if (!loginData) {
    return null;
  }
  const parsedLoginData = JSON.parse(loginData);
  const expiresAt = parsedLoginData?.accessToken?.expiresAt;
  if (dayjs(expiresAt).isBefore(dayjs())) {
    LocalStorageController.removeItem('loginData');
  }
  return parsedLoginData?.accessToken?.access_token ?? null;
};

export class API {
  static request = async <T>(method: 'GET' | 'POST' | 'DELETE', url: string, body?: any): Promise<T> => {
    const accessToken = getAuthToken();
    const headers = {
      ...(accessToken
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {}),
      ...method === 'POST' ? {
        'Content-Type': 'application/json',
      } : {
        Accept: '*/*',
      },
    };
    const apiResponse = await fetch(`${API_URL}${url}`, {
      method,
      headers,
      ...body ? {body: JSON.stringify(body) } : {},
    });
    const response = await apiResponse.json();
    if (response.statusCode === 401) {
      LocalStorageController.removeItem('loginData');
      NavigationController.redirectTo('/login');
    } else if (typeof response.statusCode !== 'undefined' &&  response.statusCode !== 200) {
        throw new Error(response.message);
    }
    return response;
  };

  static get = async <T>(url: string): Promise<T> => {
    return API.request('GET', url);
  };

  static post = <T>(url: string, body: any): Promise<T> => {
    return API.request('POST', url, body);
  };

  static del = async <T>(url: string): Promise<T> => {
    return API.request('DELETE', url);
  };
}
