import { Autocomplete, TextField } from '@mui/material';
import { useAtom } from 'jotai';
import { SetStateAction, useState } from 'react';
import {
  AdminPersonApplication,
  loadPersonApplicationInterviews,
  personApplicationInterviews,
} from '../../admin-dashboard/view-applications/view-applications.atoms';

import {
  Application,
  loadCurrentApplication,
  currentApplicationAtom,
  answersAtom,
} from '../../application/application.atoms';
import { personAtom } from '../../application/person.atoms';
import { loginAtom } from '../../login-page/login-page.atoms';
import { DataWrapper } from '../../utilities/data-wrapper';
import { useAtomApi } from '../../utilities/use-fetch';
import { AssignedApplicationView } from './assigned-application';
import {
  loadReaderCriterionForApplication,
  loadReaderScores,
  ReaderCriteria,
  readerCriterionForApplicationAtom,
  ReaderScore,
  readerScoresAtom,
} from './reader-criteria.atoms';

export const Interviews = () => {
  const [
    currentApplicationLoading,
    currentApplicationError,
    currentApplication,
  ] = useAtomApi<Application>(loadCurrentApplication, currentApplicationAtom);

  const [loginData] = useAtom(loginAtom);
  if (!loginData) {
    return null;
  }
  const { userId } = loginData.user;
  if (!userId) {
    return null;
  }

  return (
    <DataWrapper
      loading={currentApplicationLoading}
      error={currentApplicationError}
    >
      {currentApplication ? (
        <ReaderDataLoader
          applicationId={currentApplication.applicationId}
          readerId={userId}
        />
      ) : null}
    </DataWrapper>
  );
};

type ReaderCriteriaLoaderProps = { applicationId: string; readerId: string };

const ReaderDataLoader = ({
  applicationId,
  readerId,
}: ReaderCriteriaLoaderProps) => {
  const readerCriteriaLoader = loadReaderCriterionForApplication(applicationId);
  const [readerCriteriaLoading, readerCriteriaError, readerCriteria] =
    useAtomApi<ReaderCriteria[]>(
      readerCriteriaLoader,
      readerCriterionForApplicationAtom,
    );

  const readerScoresLoader = loadReaderScores(readerId, applicationId);
  const [
    readerScoresLoading,
    readerScoresError,
    readerScores,
    setReaderScores,
  ] = useAtomApi<ReaderScore[]>(readerScoresLoader, readerScoresAtom);

  const [
    personApplicationsLoading,
    personApplicationsError,
    personApplications,
  ] = useAtomApi<AdminPersonApplication[]>(
    loadPersonApplicationInterviews,
    personApplicationInterviews,
  );

  return (
    <DataWrapper
      loading={
        readerCriteriaLoading ||
        readerScoresLoading ||
        personApplicationsLoading
      }
      error={
        readerCriteriaError || readerScoresError || personApplicationsError
      }
    >
      {readerCriteria && readerScores && personApplications ? (
        <InterviewPage
          readerId={readerId}
          applicationId={applicationId}
          readerCriteria={readerCriteria}
          readerScores={readerScores}
          personApplications={personApplications}
          setReaderScores={setReaderScores}
        />
      ) : null}
    </DataWrapper>
  );
};

type InterviewPageProps = {
  readerId: string;
  applicationId: string;
  readerCriteria: ReaderCriteria[];
  readerScores: ReaderScore[];
  setReaderScores: (update: SetStateAction<ReaderScore[]>) => void;
  personApplications: AdminPersonApplication[];
};

const InterviewPage = ({
  readerId,
  applicationId,
  readerCriteria,
  readerScores,
  setReaderScores,
  personApplications,
}: InterviewPageProps) => {
  type AutocompleteOption = { id: string; label: string };
  const [selectedPersonApplication, setSelectedPersonApplication] = useState<{
    label: string;
    id: string;
  } | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_currAnswers, setCurrAnswers] = useAtom(answersAtom);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_personValue, setPersonValue] = useAtom(personAtom);

  const autoCompleteOptions = personApplications
    .map((pa) => ({
      label: `${pa.firstName} ${pa.lastName}`,
      id: pa.personApplicationId,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleApplicationChange = (
    event: React.SyntheticEvent,
    value: AutocompleteOption | null,
  ) => {
    if (!value) {
      setSelectedPersonApplication(null);
    } else {
      setCurrAnswers(null);
      setPersonValue(null);
      setSelectedPersonApplication(null);
      setTimeout(() => setSelectedPersonApplication(value));
    }
  };

  const clearSearch = () => {
    setCurrAnswers(null);
    setPersonValue(null);
    setSelectedPersonApplication(null);
  };

  const interviewCriteria = readerCriteria.filter(
    (rc) => rc.type === 'interview',
  );

  return (
    <div className="u-padding">
      <Autocomplete
        disablePortal
        value={selectedPersonApplication}
        options={autoCompleteOptions}
        onChange={handleApplicationChange}
        renderInput={(params: any) => (
          <TextField {...params} label="Interviewee" />
        )}
      />
      {selectedPersonApplication ? (
        <>
          <h1>Interview Scoring</h1>
          <p>
            Below is this interviewee's application. Underneath that, you'll
            find the interview scores
          </p>
          <AssignedApplicationView
            readerId={readerId}
            applicationId={applicationId}
            readerCriteria={interviewCriteria}
            readerScores={readerScores}
            setReaderScores={setReaderScores}
            personApplicationId={selectedPersonApplication.id}
            onScoreSave={clearSearch}
          />
        </>
      ) : null}
    </div>
  );
};
