import {
  Breadcrumbs,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  loadAnswersByPersonApplicationId,
  Answer,
  answersAtom,
} from '../../application/application.atoms';
import { loadPerson, Person, personAtom } from '../../application/person.atoms';
import {
  loadQuestions,
  Question,
  questionsAtom,
} from '../../questions/questions.atoms';
import { ScoresToShow } from '../../reader-dashboard/scoring-page/assigned-application';
import { DataWrapper } from '../../utilities/data-wrapper';
import { MAJOR_MAP } from '../../utilities/umd-information';
import { useAtomApi } from '../../utilities/use-fetch';
import {
  loadReaderCriterionForApplication,
  ReaderCriteria,
  readerCriterionForApplicationAtom,
  loadReaderAssignmentsForApplication,
  ReaderAssignment,
  readerAssignmentsForApplicationAtom,
  loadReaderScoresForApplication,
  ReaderScore,
  readerScoresForApplicationAtom,
} from '../view-scores/view-scores-atoms';
import {
  AdminPersonApplication,
  loadCurrentApplications,
  adminPersonApplicationAtom,
} from './view-applications.atoms';

export const ViewApplicationPage = () => {
  const { personApplicationId } = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_currAnswers, setCurrAnswers] = useAtom(answersAtom);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_personValue, setPersonValue] = useAtom(personAtom);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_appScores, setAppScores] = useAtom(readerScoresForApplicationAtom);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_appAssignments, setAppAssignments] = useAtom(
    readerAssignmentsForApplicationAtom,
  );
  useEffect(() => {
    setCurrAnswers(null);
    setPersonValue(null);
    setAppScores(null);
    setAppAssignments(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentAppsLoading, currentAppsError, apps] = useAtomApi<
    AdminPersonApplication[]
  >(loadCurrentApplications, adminPersonApplicationAtom);

  const applicationId = apps?.find(
    (a) => a.personApplicationId === personApplicationId,
  )?.applicationId;
  const personId = apps?.find(
    (a) => a.personApplicationId === personApplicationId,
  )?.personId;

  return (
    <DataWrapper loading={currentAppsLoading} error={currentAppsError}>
      {personApplicationId && applicationId && personId ? (
        <ViewApplicationPageComponentWithPerson
          personApplicationId={personApplicationId}
          applicationId={applicationId}
          personId={personId}
        />
      ) : null}
    </DataWrapper>
  );
};

type ViewApplicationPageComponentWithPersonComponentProps = {
  personApplicationId: string;
  applicationId: string;
  personId: string;
};

const ViewApplicationPageComponentWithPerson = ({
  personApplicationId,
  applicationId,
  personId,
}: ViewApplicationPageComponentWithPersonComponentProps) => {
  const [personLoading, personError, person] = useAtomApi<Person>(
    loadPerson(personId),
    personAtom,
  );

  const readerCriteriaLoader = loadReaderCriterionForApplication(applicationId);
  const [readerCriteriaLoading, readerCriteriaError, readerCriteria] =
    useAtomApi<ReaderCriteria[]>(
      readerCriteriaLoader,
      readerCriterionForApplicationAtom,
    );

  const readerAssignmentLoader =
    loadReaderAssignmentsForApplication(personApplicationId);
  const [readerAssignmentLoading, readerAssignmentError, readerAssignments] =
    useAtomApi<ReaderAssignment[]>(
      readerAssignmentLoader,
      readerAssignmentsForApplicationAtom,
    );

  const readerScoresLoader =
    loadReaderScoresForApplication(personApplicationId);
  const [readerScoresLoading, readerScoresError, readerScores] = useAtomApi<
    ReaderScore[]
  >(readerScoresLoader, readerScoresForApplicationAtom);

  return (
    <DataWrapper
      loading={
        personLoading ||
        readerCriteriaLoading ||
        readerAssignmentLoading ||
        readerScoresLoading
      }
      error={
        personError ||
        readerCriteriaError ||
        readerAssignmentError ||
        readerScoresError
      }
    >
      {person ? (
        <ViewApplicationPageComponent
          personApplicationId={personApplicationId}
          applicationId={applicationId}
          person={person}
          readerCriteria={readerCriteria}
          readerAssignments={readerAssignments}
          readerScores={readerScores}
        />
      ) : null}
    </DataWrapper>
  );
};

type ComponentProps = {
  personApplicationId: string;
  applicationId: string;
  person?: Person;
  respectVisibleToReaders?: boolean;
  readerCriteria?: ReaderCriteria[];
  readerAssignments?: ReaderAssignment[];
  readerScores?: ReaderScore[];
};

type AnswerToShowProps = {
  questions: Question[];
  answers?: Answer[];
  respectVisibleToReaders?: boolean;
};

export const AnswersToShow = ({
  questions,
  answers,
  respectVisibleToReaders,
}: AnswerToShowProps) => {
  const answerComponents = questions.map((q, index) => {
    if (respectVisibleToReaders && !q.visibleToReaders) {
      return null;
    }
    let answer =
      answers?.find((a) => a.questionId === q.questionId)?.response || '';
    if (q.questionType === 'referenceQuestion') {
      const [name, email, phone] = answer.split(',');
      answer = `Name: ${name}, Email: ${email}, Phone: ${phone}`;
    }
    if (q.questionType === 'cohortPreference') {
      if (answer === 'no-preference') {
        answer = 'No Preference';
      } else {
        const [cohort, preference] = answer.split('-');
        if (preference === 'only') {
          answer = `I prefer cohort ${cohort} only`;
        } else {
          answer = `I prefer cohort ${cohort}, but would join the other cohort`;
        }
      }
    }
    return (
      <TableRow key={q.questionId}>
        <TableCell component="th" scope="row" className="u-width-50">
          <b
            className="question-container"
            dangerouslySetInnerHTML={{
              __html: `${index + 1}. ${q.questionHTML}`,
            }}
          ></b>
        </TableCell>
        <TableCell>{answer}</TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer component={Paper} className="u-margin-top">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>{answerComponents}</TableBody>
      </Table>
    </TableContainer>
  );
};

export const ViewApplicationPageComponent = ({
  personApplicationId,
  applicationId,
  person,
  respectVisibleToReaders,
  readerCriteria,
  readerAssignments,
  readerScores,
}: ComponentProps) => {
  const questionLoader = loadQuestions(applicationId);
  const [questionLoading, questionError, questions] = useAtomApi<Question[]>(
    questionLoader,
    questionsAtom,
  );

  const answerLoader = loadAnswersByPersonApplicationId(personApplicationId);
  const [answerLoading, answerError, answers] = useAtomApi<Answer[]>(
    answerLoader,
    answersAtom,
  );

  const isLoading = questionLoading || answerLoading;
  const hasError = questionError || answerError;

  const sortedQuestions =
    questions?.sort((a, b) => a.sortOrder - b.sortOrder) || [];

  const GenderValues = {
    m: 'Male',
    f: 'Female',
    n: 'Non-Binary',
    o: 'Prefer not to answer',
  };

  const PronounValues = {
    h: 'He/Him',
    s: 'She/Her',
    t: 'They/Them',
    z: 'Ze/Zir',
    n: 'A pronoun not listed',
    o: 'No pronoun preference',
    p: 'Prefer not to answer',
  };

  const ApplicationBreadcrumb = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin-dashboard/view-applications">All Applications</Link>
        <Typography color="text.primary">{personApplicationId}</Typography>
      </Breadcrumbs>
    );
  };

  const readerCriteriaReader = readerCriteria?.filter(
    (rc) => rc.type === 'reader',
  );
  const readerCriteriaInterview = readerCriteria?.filter(
    (rc) => rc.type === 'interview',
  );

  const hasReaderScoresToShow = Boolean(
    readerCriteriaReader && readerAssignments && readerScores,
  );

  const hasInterviewScoresToShow = Boolean(
    readerCriteriaInterview && readerScores,
  );

  type ScoreUserMap = { [userId: string]: ReaderScore[] };
  let readerScoresByUser: ScoreUserMap = {};
  let sortedReaderCriteria: ReaderCriteria[] = [];
  if (readerCriteriaReader && readerAssignments && readerScores) {
    readerScoresByUser = readerScores.reduce((prev, curr) => {
      if (
        !readerCriteriaReader.find(
          (rcr) => rcr.readerCriteriaId === curr.readerCriteriaId,
        )
      ) {
        return prev;
      }
      if (prev[curr.readerId]) {
        prev[curr.readerId].push(curr);
      } else {
        prev[curr.readerId] = [curr];
      }
      return prev;
    }, {} as ScoreUserMap);

    sortedReaderCriteria = readerCriteriaReader.sort(
      (a, b) => a.sortOrder - b.sortOrder,
    );
  }

  let interviewScoresByUser: ScoreUserMap = {};
  let sortedInterviewCriteria: ReaderCriteria[] = [];
  if (readerCriteriaInterview && readerScores) {
    interviewScoresByUser = readerScores.reduce((prev, curr) => {
      if (
        !readerCriteriaInterview.find(
          (rcr) => rcr.readerCriteriaId === curr.readerCriteriaId,
        )
      ) {
        return prev;
      }
      if (prev[curr.readerId]) {
        prev[curr.readerId].push(curr);
      } else {
        prev[curr.readerId] = [curr];
      }
      return prev;
    }, {} as ScoreUserMap);

    sortedInterviewCriteria = readerCriteriaInterview.sort(
      (a, b) => a.sortOrder - b.sortOrder,
    );
  }

  return (
    <DataWrapper loading={isLoading} error={hasError}>
      <div className="u-padding">
        {person ? (
          <>
            <ApplicationBreadcrumb />
            <h1>Profile Information</h1>
            {person ? (
              <TableContainer component={Paper} className="u-margin-top">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Name</b>
                      </TableCell>
                      <TableCell>
                        {person.preferredName &&
                        person.preferredName !== person.firstName
                          ? `${person.preferredName} (${person.firstName})`
                          : person.firstName}{' '}
                        {person.lastName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Email</b>
                      </TableCell>
                      <TableCell>{person.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Phone Number</b>
                      </TableCell>
                      <TableCell>{person.phoneNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Gender</b>
                      </TableCell>
                      <TableCell>
                        {person.gender
                          ? GenderValues[
                              person.gender as keyof typeof GenderValues
                            ]
                          : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Pronouns</b>
                      </TableCell>
                      <TableCell>
                        {person.pronouns
                          ? PronounValues[
                              person.pronouns as keyof typeof PronounValues
                            ]
                          : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Race</b>
                      </TableCell>
                      <TableCell>{person.race}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>GPA</b>
                      </TableCell>
                      <TableCell>{person.gpa}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>First Generation College Student</b>
                      </TableCell>
                      <TableCell>
                        {person.firstGenerationCollegeStudent === 'y'
                          ? 'Yes'
                          : 'No'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Credits Completed at UMD</b>
                      </TableCell>
                      <TableCell>{person.creditsCompletedAtUMD}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Planned Graduating Year</b>
                      </TableCell>
                      <TableCell>{person.plannedGraduatingYear}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Schools</b>
                      </TableCell>
                      <TableCell>
                        {person.major
                          ? Array.from(
                              new Set(
                                person.major.map((m) => MAJOR_MAP[m]?.school),
                              ),
                            ).join(', ')
                          : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Majors</b>
                      </TableCell>
                      <TableCell>
                        {person.major ? person.major.join(', ') : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Honors Program</b>
                      </TableCell>
                      <TableCell>
                        {person.honorsProgram
                          ? person.honorsProgram.join(', ')
                          : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Freshman Connection</b>
                      </TableCell>
                      <TableCell>
                        {person.freshmanConnection ? 'Yes' : 'No'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Extenuating Circumstances</b>
                      </TableCell>
                      <TableCell>
                        {person.questRequirementExtenuatingCircumstances}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </>
        ) : null}
        <h1 className="u-margin-top">Application</h1>
        <AnswersToShow
          questions={sortedQuestions}
          answers={answers}
          respectVisibleToReaders={respectVisibleToReaders}
        />
        {hasReaderScoresToShow ? (
          <>
            <h1 className="u-margin-top">Reader Scores</h1>
            {Object.keys(readerScoresByUser).map((readerId) => {
              const userScores = readerScoresByUser[readerId];
              const readerAssignment = readerAssignments?.find(
                (ra) =>
                  ra.readerId === readerId &&
                  ra.personApplicationId === personApplicationId,
              );
              if (!readerAssignment) {
                return null;
              }

              return (
                <div key={readerId}>
                  <h1>{readerAssignment.readerUsername}</h1>
                  <p>
                    {readerAssignment.submittedAt
                      ? `Submitted at: ${dayjs(
                          readerAssignment.submittedAt,
                        ).format('MM/DD/YYYY')}`
                      : null}
                  </p>
                  <ScoresToShow
                    readerScores={userScores}
                    readerCriteria={sortedReaderCriteria}
                    showOnlyTitles
                  />
                </div>
              );
            })}
          </>
        ) : null}
        {hasInterviewScoresToShow ? (
          <>
            <h1 className="u-margin-top">Interview Scores</h1>
            {Object.keys(interviewScoresByUser).map((readerId) => {
              const userScores = interviewScoresByUser[readerId];
              const readerUsername = userScores[0].readerUsername;

              return (
                <div key={readerId}>
                  <h1>{readerUsername}</h1>
                  <ScoresToShow
                    readerScores={userScores}
                    readerCriteria={sortedInterviewCriteria}
                    showOnlyTitles
                  />
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    </DataWrapper>
  );
};
