import { FieldValues } from 'react-hook-form';
import { getWordCount } from '../../utilities/get-word-count';
import { FormTextField } from '../../utilities/react-hook-form-connectors/form-text-field';
import { QuestionProps } from './QuestionProps';

const MAX_WORD_LENGTH = 200;

export const TextQuestion = <T extends FieldValues>({
  control,
  name,
  label,
  index,
  required,
}: QuestionProps<T>) => {
  return (
    <div className="u-margin-top">
      <div
        className="question-container"
        dangerouslySetInnerHTML={{
          __html: `${index}. ${label} (in ${MAX_WORD_LENGTH} words or less)`,
        }}
      ></div>
      <FormTextField
        control={control}
        name={name}
        multiline
        required={required}
        rules={{
          validate: {
            lessThanTwoHundred: (value) => {
              const wordCount = getWordCount(value) ?? 0;
              if (wordCount === undefined) {
                return;
              }
              if (wordCount > MAX_WORD_LENGTH) {
                return `Please use ${MAX_WORD_LENGTH} words or less. Current word count: ${wordCount}`;
              }
            },
          },
        }}
      />
    </div>
  );
};
