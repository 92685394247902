import { atom } from 'jotai';
import { API } from '../../utilities/api';

export type ReaderCriteria = {
  readerCriteriaId: string;
  applicationId: string;
  sortOrder: number;
  title: string;
  criteriaHTML: string;
  createdAt: Date;
  type: 'reader' | 'interview';
};

export type ReaderAssignment = {
  readerAssignmentId: string;
  readerId: string;
  readerUsername: string;
  personApplicationId: string;
  major: string | null;
  submittedAt: string | null;
};

export type ReaderScore = {
  readerScoreId: string;
  readerId: string;
  readerUsername: string;
  readerCriteriaId: string;
  readerCriteriaTitle: string;
  readerCriteriaType: string;
  personApplicationId: string;
  score: number | null;
  comment: string | null;
  createdAt: Date | null;
};

export type IsInterviewer = {
  isInterviewer: boolean;
};

export const loadReaderCriterionForApplication =
  (applicationId: string) => async () => {
    return API.get<ReaderCriteria[]>(
      `/reader-criteria?applicationId=${applicationId}`,
    );
  };
export const readerCriterionForApplicationAtom =
  atom<AtomLoadedData<ReaderCriteria[]>>(null);

export const loadReaderAssignments =
  (readerId: string, applicationId: string) => async () => {
    return API.get<ReaderAssignment[]>(
      `/reader-assignments?readerId=${readerId}&applicationId=${applicationId}`,
    );
  };
export const readerAssignmentsAtom =
  atom<AtomLoadedData<ReaderAssignment[]>>(null);

export const loadReaderScores =
  (readerId: string, applicationId: string) => async () => {
    return API.get<ReaderScore[]>(
      `/reader-scores?readerId=${readerId}&applicationId=${applicationId}`,
    );
  };
export const readerScoresAtom = atom<AtomLoadedData<ReaderScore[]>>(null);

export const loadIsInterview = (username?: string) => async () => {
  if (!username) {
    return null;
  }
  return API.get<IsInterviewer>(
    `/reader-assignments/is-interviewer?username=${username}`,
  );
};
export const isInterviewerAtom = atom<AtomLoadedData<IsInterviewer>>(null);
