import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

type FormSelectProps<T extends FieldValues> = UseControllerProps<T> & {
  label?: string;
  children: React.ReactNode;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  multiple?: boolean;
};

export const FormSelect = <T extends FieldValues>({
  name,
  label,
  control,
  required,
  className,
  rules,
  children,
  disabled,
  multiple,
}: FormSelectProps<T>) => (
  <Controller
    name={name}
    control={control}
    rules={{
      ...rules,
      required: required || rules?.required ? 'Required' : false,
    }}
    render={({ field, fieldState: { error } }) => (
      <FormControl fullWidth className={className} error={Boolean(error)}>
        {label ? (
          <InputLabel error={Boolean(error)} required={required}>
            {label}
          </InputLabel>
        ) : null}
        <Select {...field} disabled={disabled} multiple={multiple}>
          {children}
        </Select>
        <FormHelperText>{error?.message}</FormHelperText>
      </FormControl>
    )}
  />
);
