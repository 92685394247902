import Button from '@mui/material/Button';
import { API } from './api';
import { collectConfirmation } from './confirmation-dialog';

type InputProps = {
  id: string;
  path: string;
  name: string;
  onDelete: (id: string) => void;
};

export const DeleteButton = ({ id, path, name, onDelete }: InputProps) => {
  const onClick = async () => {
    if (
      await collectConfirmation(`Are you sure you want to delete this ${name}?`)
    ) {
      await API.del(`${path}${id}`);
      onDelete(id);
    }
  };
  return <Button onClick={onClick}>Delete</Button>;
};
