import TextField from '@mui/material/TextField';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

type TextFieldInput<T extends FieldValues> = UseControllerProps<T> & {
  label?: string;
  required?: boolean;
  className?: string;
  type?: React.InputHTMLAttributes<unknown>['type'];
  multiline?: boolean;
  rows?: number;
};

export const FormTextField = <T extends FieldValues>({
  control,
  name,
  rules,
  type,
  label,
  className,
  required,
  multiline,
  rows,
}: TextFieldInput<T>) => {
  const controllerProps = {
    control,
    name,
    rules: {
      ...rules,
      required: required || rules?.required ? 'Required' : false,
    },
  };

  const fieldProps = {
    type,
    label,
    multiline,
    rows,
    className,
    required: Boolean(required || rules?.required),
    ...(multiline ? { rows: 6 } : {}),
  };
  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...fieldProps}
          {...field}
          fullWidth
          error={Boolean(error)}
          helperText={error?.message}
        />
      )}
    />
  );
};
