import { atom } from 'jotai';
import { API } from '../utilities/api';

type LandingPageData = {
    questAdminToContact: {
        name: string,
        email: string,
    },
    currentApplication: {
        dueDate: string,
        cohorts: Array<string>
    }
}

export const landingPageData = atom(API.get<LandingPageData>('/landing-page-data'));
