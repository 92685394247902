import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { API } from '../../utilities/api';
import { DialogWrapper } from '../../utilities/dialog-wrapper';
import { FormCheckbox } from '../../utilities/react-hook-form-connectors/form-checkbox';
import { FormRichTextField } from '../../utilities/react-hook-form-connectors/form-rich-text-field';
import { FormSelect } from '../../utilities/react-hook-form-connectors/form-select';
import { FormTextField } from '../../utilities/react-hook-form-connectors/form-text-field';
import { Question } from '../../questions/questions.atoms';

type InputProps = {
  applicationId: string;
  question?: Question;
  isCreate?: boolean;
  onSave: (questionData: Question) => void;
};

const QUESTION_TYPES = {
  shortText: 'Short Text',
  text: 'Text',
  cohortPreference: 'Cohort Preference',
  questLearningQuestion: 'How did you learn about QUEST',
  referenceQuestion: 'Reference',
};

export const QuestionEditorDialog = ({
  question,
  isCreate,
  onSave,
  applicationId,
}: InputProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const handleToggleDialog = () => setIsDialogOpen(!isDialogOpen);
  const label = isCreate ? 'Add' : 'Edit';

  const defaultValues = {
    sortOrder: question?.sortOrder ?? 1,
    questionHTML: question?.questionHTML ?? '',
    questionType: question?.questionType ?? 'text',
    visibleToReaders: question?.visibleToReaders ?? true,
  };

  const {
    control,
    // setValue,
    formState: { isDirty, errors },
    handleSubmit,
    // watch,
    reset,
    // trigger,
  } = useForm({ defaultValues });

  const hasErrors = Object.keys(errors).length > 0;

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    const updatedQuestion = await API.post<Question>(
      `/questions/${question?.questionId ?? ''}`,
      {
        ...data,
        applicationId,
      },
    );
    reset(data);
    setIsDialogOpen(false);
    onSave(updatedQuestion);
  };

  return (
    <>
      <Button onClick={handleToggleDialog}>{label}</Button>
      {isDialogOpen ? (
        <DialogWrapper onClose={handleToggleDialog} title={label} size="md">
          <DialogWrapper.Content>
            <FormTextField
              control={control}
              type="number"
              name="sortOrder"
              label="Sort Order"
            />
            <FormSelect
              control={control}
              name="questionType"
              label="Question Type"
              className="u-margin-top"
            >
              {Object.keys(QUESTION_TYPES).map((questionType) => (
                <MenuItem key={questionType} value={questionType}>
                  {QUESTION_TYPES[questionType as keyof typeof QUESTION_TYPES]}
                </MenuItem>
              ))}
            </FormSelect>
            <FormCheckbox
              control={control}
              name="visibleToReaders"
              label="Show to Readers?"
            />
            <FormRichTextField
              control={control}
              name="questionHTML"
              label="Question Text"
            />
          </DialogWrapper.Content>
          <DialogWrapper.Actions>
            <Button variant="contained" onClick={handleToggleDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={hasErrors || !isDirty}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </DialogWrapper.Actions>
        </DialogWrapper>
      ) : null}
    </>
  );
};
