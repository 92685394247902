import { atom } from 'jotai';
import { API } from '../../utilities/api';

export type AdminPersonApplication = {
  personApplicationId: string;
  applicationId: string;
  personId: string;
  createdAt: string;
  firstName?: string;
  lastName?: string;
  submittedAt?: string;
  hasInterview?: boolean;
};

export const loadCurrentApplications = async () => {
  return API.get<AdminPersonApplication[]>(`/person-application/all`);
};
export const adminPersonApplicationAtom =
  atom<AtomLoadedData<AdminPersonApplication[]>>(null);

export const loadPersonApplicationInterviews = async () => {
  return API.get<AdminPersonApplication[]>(`/person-application/interviews`);
};
export const personApplicationInterviews =
  atom<AtomLoadedData<AdminPersonApplication[]>>(null);
