import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import dayjs from 'dayjs';

import { NavigationController } from '../../bootstrap/history-spy';
import { API } from '../../utilities/api';
import { csvExporter } from '../../utilities/csv-exporter';
import { DataWrapper } from '../../utilities/data-wrapper';
import { useAtomApi } from '../../utilities/use-fetch';
import {
  loadCurrentApplications,
  adminPersonApplicationAtom,
  AdminPersonApplication,
} from './view-applications.atoms';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export const ViewApplicationsPage = () => {
  const [loading, error, apps] = useAtomApi<AdminPersonApplication[]>(
    loadCurrentApplications,
    adminPersonApplicationAtom,
  );

  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);

  const dataGridRows = (apps || []).map((app) => ({
    ...app,
    id: app.personApplicationId,
  }));

  const handleClickExport = async () => {
    setIsLoadingExport(true);
    const exportData = await API.get<any>('/application-export');
    setIsLoadingExport(false);

    const filename = `question_applications_${dayjs().format('MM_DD_YY')}.csv`;
    const exportFields = Object.keys(exportData[0]).map((column) => ({
      label: column,
      value: column,
    }));

    exportFields.sort((a, b) => {
      const aIsQuestion = a.label.startsWith('Question');
      const bIsQuestion = b.label.startsWith('Question');
      if (!aIsQuestion || !bIsQuestion) {
        return 0;
      }
      if (aIsQuestion && bIsQuestion) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_aq, aNumbers] = a.label.split(' ');
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_bq, bNumbers] = b.label.split(' ');

        const aNum = Number.parseInt(aNumbers, 10);
        const bNum = Number.parseInt(bNumbers, 10);

        return aNum > bNum ? 1 : -1;
      }
      if (aIsQuestion && !bIsQuestion) {
        return -1;
      }
      if (bIsQuestion && !aIsQuestion) {
        return 1;
      }
      return 0;
    });

    const result = csvExporter(filename, exportFields, exportData);
    if (!result) {
      console.error(result);
    }
  };

  const columns: GridColDef<AdminPersonApplication>[] = [
    { field: 'id', headerName: 'Application ID', flex: 1 },
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    {
      field: 'createdAt',
      headerName: 'Created',
      valueGetter: (params) => dayjs(params.row.createdAt).format('MM/DD/YYYY'),
      flex: 1,
    },
    {
      field: 'submittedAt',
      headerName: 'Submission Date',
      valueGetter: (params) =>
        params.row.submittedAt
          ? dayjs(params.row.submittedAt).format('MM/DD/YYYY')
          : '',
      flex: 1,
    },
    {
      field: 'hasInterview',
      headerName: 'Has Interview',
      valueGetter: (params) => (params.row.hasInterview ? 'Yes' : 'No'),
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      flex: 1,
      renderHeader: () => (
        <Button onClick={handleClickExport} disabled={isLoadingExport}>
          {isLoadingExport && (
            <CircularProgress
              color="inherit"
              className="u-margin-right"
              size={15}
            />
          )}
          Export
        </Button>
      ),
      renderCell: (params) => (
        <Button
          onClick={() =>
            NavigationController.redirectTo(
              `/admin-dashboard/view-applications/${params.row.personApplicationId}`,
            )
          }
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <DataWrapper loading={loading} error={error}>
      <DataGrid rows={dataGridRows} columns={columns} />
    </DataWrapper>
  );
};
