import { useAtom } from 'jotai';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { loginAtom } from '../login-page/login-page.atoms';

const PATH_TO_TAB_CONFIG = {
  '/admin-dashboard/manage-applications': 0,
  '/admin-dashboard/manage-application': 0,
  '/admin-dashboard/preview-application': 1,
  '/admin-dashboard/view-applications': 2,
  '/admin-dashboard/view-scores': 3,
  '/admin-dashboard/view-score': 3,
  '/admin-dashboard/view-interview-scores': 4,
  '/admin-dashboard/interviews': 5,
};
const TAB_TO_PATH_CONFIG = [
  '/admin-dashboard/manage-applications',
  '/admin-dashboard/preview-application',
  '/admin-dashboard/view-applications',
  '/admin-dashboard/view-scores',
  '/admin-dashboard/view-interview-scores',
  '/admin-dashboard/interviews',
];

export const AdminDashboard = () => {
  const [loginData] = useAtom(loginAtom);
  const navigate = useNavigate();
  const location = useLocation();

  const tabConfigKey = Object.keys(PATH_TO_TAB_CONFIG).find((val) => {
    return location.pathname.includes(val);
  });
  const tabIndex = tabConfigKey
    ? PATH_TO_TAB_CONFIG[tabConfigKey as keyof typeof PATH_TO_TAB_CONFIG] ?? 0
    : 0;

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    navigate(TAB_TO_PATH_CONFIG[newValue]);
  };

  if (loginData?.user.role !== 'admin') {
    navigate('/');
    return null;
  }

  return (
    <div className="with-sidebar u-height-100">
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabIndex}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Manage Application Metadata" />
        <Tab label="Preview Application" />
        <Tab label="View Applications" />
        <Tab label="Reader Scores" />
        <Tab label="Interview Scores" />
        <Tab label="Interview Experience" />
      </Tabs>
      <Outlet />
    </div>
  );
};
