import { useAtom } from 'jotai';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loginAtom } from './login-page.atoms';

export const LoginExchangePage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loginDataAtom, setLoginData] = useAtom(loginAtom);

  const loginDataString = searchParams.get('loginData');
  if (!loginDataString) {
    navigate('/');
    return null;
  }
  const loginData = JSON.parse(loginDataString);
  setLoginData(loginData);

  if (loginDataAtom?.user.role === 'admin') {
    navigate('/admin-dashboard/manage-applications');
  }
  if (loginDataAtom?.user.role === 'applicant') {
    // TODO: Handle UID users not have a person during creation
    navigate('/applicant-dashboard/profile');
  }
  if (loginDataAtom?.user.role === 'reader') {
    navigate('/reader-dashboard/applications');
  }

  return null;
};
