import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAtom } from 'jotai';

import {
  Application,
  loadCurrentApplication,
  currentApplicationAtom,
} from '../../application/application.atoms';
import { NavigationController } from '../../bootstrap/history-spy';
import { loginAtom } from '../../login-page/login-page.atoms';
import { DataWrapper } from '../../utilities/data-wrapper';
import { useAtomApi } from '../../utilities/use-fetch';
import {
  loadReaderAssignments,
  loadReaderCriterionForApplication,
  loadReaderScores,
  ReaderAssignment,
  readerAssignmentsAtom,
  ReaderCriteria,
  readerCriterionForApplicationAtom,
  ReaderScore,
  readerScoresAtom,
} from './reader-criteria.atoms';

export const AssignedApplicationList = () => {
  const [
    currentApplicationLoading,
    currentApplicationError,
    currentApplication,
  ] = useAtomApi<Application>(loadCurrentApplication, currentApplicationAtom);

  const [loginData] = useAtom(loginAtom);
  if (!loginData) {
    return null;
  }
  const { userId } = loginData.user;
  if (!userId) {
    return null;
  }

  return (
    <DataWrapper
      loading={currentApplicationLoading}
      error={currentApplicationError}
    >
      {currentApplication ? (
        <ReaderDataLoader
          applicationId={currentApplication.applicationId}
          readerId={userId}
        />
      ) : null}
    </DataWrapper>
  );
};

type ReaderCriteriaLoaderProps = { applicationId: string; readerId: string };

const ReaderDataLoader = ({
  applicationId,
  readerId,
}: ReaderCriteriaLoaderProps) => {
  const readerCriteriaLoader = loadReaderCriterionForApplication(applicationId);
  const [readerCriteriaLoading, readerCriteriaError, readerCriteria] =
    useAtomApi<ReaderCriteria[]>(
      readerCriteriaLoader,
      readerCriterionForApplicationAtom,
    );

  const readerAssignmentLoader = loadReaderAssignments(readerId, applicationId);
  const [readerAssignmentLoading, readerAssignmentError, readerAssignments] =
    useAtomApi<ReaderAssignment[]>(
      readerAssignmentLoader,
      readerAssignmentsAtom,
    );

  const readerScoresLoader = loadReaderScores(readerId, applicationId);
  const [readerScoresLoading, readerScoresError, readerScores] = useAtomApi<
    ReaderScore[]
  >(readerScoresLoader, readerScoresAtom);

  return (
    <DataWrapper
      loading={
        readerCriteriaLoading || readerAssignmentLoading || readerScoresLoading
      }
      error={readerCriteriaError || readerAssignmentError || readerScoresError}
    >
      {readerCriteria && readerAssignments && readerScores ? (
        <AssignedApplications
          readerCriteria={readerCriteria}
          readerAssignments={readerAssignments}
          readerScores={readerScores}
        />
      ) : null}
    </DataWrapper>
  );
};

type AssignedApplicationsProps = {
  readerCriteria: ReaderCriteria[];
  readerAssignments: ReaderAssignment[];
  readerScores: ReaderScore[];
};

const AssignedApplications = ({
  readerCriteria,
  readerAssignments,
  readerScores,
}: AssignedApplicationsProps) => {
  type AssignedApp = {
    id: string;
    major: string | null;
    criteriaScored: string;
    hasBeenSubmitted: string;
  };

  const dataGridRows: AssignedApp[] = readerAssignments.map((ra) => {
    const currentScores = readerScores.filter(
      (rs) =>
        rs.personApplicationId === ra.personApplicationId &&
        rs.readerCriteriaType === 'reader',
    );
    const readerCriteriaReader = readerCriteria.filter(
      (rc) => rc.type === 'reader',
    );
    return {
      id: ra.readerAssignmentId,
      major: ra.major,
      criteriaScored: `${currentScores.length}/${readerCriteriaReader.length}`,
      hasBeenSubmitted: Boolean(ra.submittedAt) ? 'Yes' : 'No',
    };
  });

  const columns: GridColDef<AssignedApp>[] = [
    { field: 'id', headerName: 'Application ID', flex: 1 },
    { field: 'criteriaScored', headerName: 'Progress', flex: 1 },
    { field: 'hasBeenSubmitted', headerName: 'Submitted?', flex: 1 },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() =>
            NavigationController.redirectTo(
              `/reader-dashboard/application/${params.row.id}`,
            )
          }
        >
          View
        </Button>
      ),
    },
  ];

  return <DataGrid rows={dataGridRows} columns={columns} />;
};
