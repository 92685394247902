import { SetStateAction, Atom, useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';

export const useAtomApi = <T>(
  request: () => Promise<AtomLoadedData<T>>,
  atom: Atom<AtomLoadedData<T>>,
  onSuccess?: (value: T) => void,
): [boolean, any, T, (update: SetStateAction<T>) => void] => {
  const [value, setValue] = useAtom<AtomLoadedData<T>>(atom);
  const [loading, error] = useApi<AtomLoadedData<T>, T>(
    request,
    setValue,
    value as T,
    onSuccess,
  );
  return [loading, error, value as T, setValue];
};

export const useApi = <T, S>(
  request: () => Promise<T>,
  atomSetter?: (update: SetStateAction<T>) => void,
  cachedValue?: T,
  onSuccess?: (value: S) => void,
) => {
  const [response, setResponse] = useState<T | null>(cachedValue ?? null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (cachedValue || loading) {
      return;
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setLoading(true);
      request()
        .then((data) => {
          if (atomSetter) {
            atomSetter(data);
          }
          setResponse(data);
          setLoading(false);
          if (onSuccess) {
            onSuccess(data as unknown as S);
          }
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    }
  }, [atomSetter, cachedValue, loading, onSuccess, request]);
  return [loading, error, response];
};
