import { createBrowserRouter } from 'react-router-dom';

import { LandingPage } from '../landing-page/landing-page';
import { LoginPage } from '../login-page/login-page';
import { LoginExchangePage } from '../login-page/login-exchange';
import { AdminDashboard } from '../admin-dashboard/admin-dashboard';
import { ErrorPage } from '../utility-pages/not-found';
import { HistorySpy } from './history-spy';

import { ManageApplicationsPage } from '../admin-dashboard/manage-applications/manage-applications-page';
import { ViewApplicationsPage } from '../admin-dashboard/view-applications/view-applications-page';
import { ViewApplicationPage } from '../admin-dashboard/view-applications/view-application-page';
import { PreviewApplicationPage } from '../admin-dashboard/preview-application/preview-application-page';
import { ManageApplicationPage } from '../admin-dashboard/manage-applications/manage-application-page';

import { ApplicantDashboard } from '../applicant-dashboard/applicant-dashboard';
import { PersonPage } from '../application/person-page';
import { ApplicationPage } from '../application/application-page';

import { ReaderDashboard } from '../reader-dashboard/reader-dashboard';
import { AssignedApplicationList } from '../reader-dashboard/scoring-page/assigned-application-list';
import { AssignedApplication } from '../reader-dashboard/scoring-page/assigned-application';
import { Interviews } from '../reader-dashboard/scoring-page/interviews';

import { ViewScoresPage } from '../admin-dashboard/view-scores/view-scores-page';
import { ViewInterviewScoresPage } from '../admin-dashboard/view-scores/view-interview-scores-page';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <HistorySpy />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <LandingPage />,
      },
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/login-exchange',
        element: <LoginExchangePage />,
      },
      {
        path: '/admin-dashboard',
        element: <AdminDashboard />,
        children: [
          {
            path: '/admin-dashboard/manage-applications',
            element: <ManageApplicationsPage />,
          },
          {
            path: '/admin-dashboard/manage-application/:applicationId',
            element: <ManageApplicationPage />,
          },
          {
            path: '/admin-dashboard/view-applications',
            element: <ViewApplicationsPage />,
          },
          {
            path: '/admin-dashboard/view-applications/:personApplicationId',
            element: <ViewApplicationPage />,
          },
          {
            path: '/admin-dashboard/preview-application',
            element: <PreviewApplicationPage />,
          },
          {
            path: '/admin-dashboard/view-scores',
            element: <ViewScoresPage />,
          },
          {
            path: '/admin-dashboard/view-interview-scores',
            element: <ViewInterviewScoresPage />,
          },
          {
            path: '/admin-dashboard/interviews',
            element: <Interviews />,
          },
        ],
      },
      {
        path: '/applicant-dashboard',
        element: <ApplicantDashboard />,
        children: [
          {
            path: '/applicant-dashboard/profile',
            element: <PersonPage />,
          },
          {
            path: '/applicant-dashboard/application',
            element: <ApplicationPage />,
          },
        ],
      },
      {
        path: '/reader-dashboard',
        element: <ReaderDashboard />,
        children: [
          {
            path: '/reader-dashboard/applications',
            element: <AssignedApplicationList />,
          },
          {
            path: '/reader-dashboard/application/:readerAssignmentId',
            element: <AssignedApplication />,
          },
          {
            path: '/reader-dashboard/interviews',
            element: <Interviews />,
          },
        ],
      },
    ],
  },
]);
